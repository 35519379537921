import React from 'react';
import ReactDOM from 'react-dom/client';
import 'src/index.css';
import reportWebVitals from 'src/reportWebVitals.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { appTheme } from './theme';
import 'assets/css/style.css';
import { CanvasProvider } from 'contexts/canvasContext';
import App from 'App';

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <BrowserRouter>
      <ThemeProvider theme={appTheme}>
        <CssBaseline enableColorScheme />
        <CanvasProvider>
          <App />
        </CanvasProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
