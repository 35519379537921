import React, { useEffect, useState } from 'react';
import VendorAlerts from './VendorAlerts';
import JobAlerts from './JobAlerts';
import BillAlerts from './BillAlerts';
import CustomerAlerts from './CustomerAlerts';
import { useAdminWorkspaceStore, useCommonStore } from 'store/index';
import { useQuery } from '@tanstack/react-query';
import { deleteQBAlert, getAllUsers, getQBAlerts } from 'api/adminHub';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import TabsComp from 'components/Tabs';
import { tabTitle } from 'utils/index';
import CustomDialog from 'components/NewLayout/Dialog';
import { Typography } from '@mui/material';
import CreateProject from 'pages/AdminHub/ProjectWorkspace/components/CreateProject';
import CreateVendor from 'pages/AdminHub/VendorWorkspace/components/CreateVendor';
import CreateCustomer from 'pages/AdminHub/CustomerWorkspace/components/CreateCustomer';
import type { User } from 'api/types';
import type { DropdownOptionType } from 'components/inputs/Dropdown';

export default function QuickbooksAlert({
  open,
  setOpen,
  openSection
}: {
  open: boolean;
  setOpen: (status: boolean) => void;
  openSection: number;
}) {
  tabTitle('Quickbook Alerts');
  const setComponentHeading = useCommonStore((state) => state.setComponentHeading);

  useEffect(() => {
    setComponentHeading('Quickbook Alerts');
  }, []);

  const setQBAlerts = useAdminWorkspaceStore((state) => state.setQBAlerts);
  const qbAlerts = useAdminWorkspaceStore((state) => state.qbAlerts);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [oneXerpId, setOneXerpId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [openUpdateVendor, setOpenUpdateVendor] = useState<boolean>(false);
  const [openUpdateCustomer, setOpenUpdateCustomer] = useState<boolean>(false);
  const [openUpdateJob, setOpenUpdateJob] = useState<boolean>(false);
  const [projectManagerUsers, setProjectManagerUsers] = useState<DropdownOptionType[]>([]);

  const vendorsAlerts = qbAlerts.filter((a) =>
    ['UPDATE_VENDOR', 'CREATE_VENDOR'].includes(a.action_type)
  );
  const jobAlerts = qbAlerts.filter((a) => ['UPDATE_JOB', 'CREATE_JOB'].includes(a.action_type));
  const billAlerts = qbAlerts.filter((a) => ['UPDATE_BILL', 'CREATE_BILL'].includes(a.action_type));
  const customerAlerts = qbAlerts.filter((a) =>
    ['UPDATE_CUSTOMER', 'CREATE_CUSTOMER'].includes(a.action_type)
  );

  useQuery({
    queryKey: ['alerts'],
    queryFn: getQBAlerts,
    staleTime: 5000,
    onSuccess: (alerts) => {
      setQBAlerts(alerts);
    },
    onError: (error) => {
      console.log(error);
    }
  });

  useEffect(() => {
    setLoading(true);
    Promise.all([getAllUsers()])
      .then(([usersResponse]) => {
        setProjectManagerUsers(
          usersResponse?.data
            .filter((u: User) => u.user_role === 'admin' || u.user_role === 'project_manager')
            .map((u: User) => ({
              id: u?.user_id ?? '',
              value: `${u.first_name} ${u.last_name}`
            }))
        );
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onDeleteQBAlert = (id: string) => {
    setOneXerpId(id);
    setShowModal(true);
  };

  const onConfirmDelete = () => {
    setLoading(true);
    deleteQBAlert(oneXerpId)
      .then((res) => {
        setLoading(false);
        setShowModal(false);
        if (res.message == 'QB Alert deleted successfully!') {
          const updatedAlerts = qbAlerts.filter((a) => a.oneXerp_id != oneXerpId);
          setQBAlerts(updatedAlerts);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const data = [
    {
      label: `Vendor Alerts (${vendorsAlerts.length})`,
      component: (
        <VendorAlerts
          onClickDelete={onDeleteQBAlert}
          onEdit={() => {
            setOpen(false);
            setOpenUpdateVendor(true);
          }}
        />
      )
    },
    {
      label: `Job Alerts (${jobAlerts.length})`,
      component: (
        <JobAlerts
          onClickDelete={onDeleteQBAlert}
          onEdit={() => {
            setOpen(false);
            setOpenUpdateJob(true);
          }}
        />
      )
    },
    {
      label: `Bill Alerts (${billAlerts.length})`,
      component: <BillAlerts onClickDelete={onDeleteQBAlert} />
    },
    {
      label: `Customer Alerts (${customerAlerts.length})`,
      component: (
        <CustomerAlerts
          onClickDelete={onDeleteQBAlert}
          onEdit={() => {
            setOpen(false);
            setOpenUpdateCustomer(true);
          }}
        />
      )
    }
  ];

  return (
    <>
      <CustomDialog
        open={open}
        closeDialog={() => setOpen(false)}
        maxWidth="md"
        loading={loading}
        dontDisplayCloseBtn
        title={
          <Typography component="div" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography component="div" sx={{ fontSize: '18px' }}>
              QB Sync Alerts
            </Typography>
            <Typography
              component="div"
              sx={{
                background: '#a84d69',
                color: '#fff',
                borderRadius: '20px',
                padding: '6px 12px'
              }}>
              Total Alerts {qbAlerts.length}
            </Typography>
          </Typography>
        }
        content={
          <>
            <TabsComp tabs={data} variant="fullWidth" openSection={openSection} />
            <DeleteConfirmationModal
              showModal={showModal}
              setShowModal={(s) => setShowModal(s)}
              onClickConfirm={() => onConfirmDelete()}
              loader={loading}
            />
          </>
        }
      />
      <CreateProject
        projectManagerUsers={projectManagerUsers}
        open={openUpdateJob}
        setOpen={(status) => setOpenUpdateJob(status)}
        action="update"
      />
      <CreateVendor
        open={openUpdateVendor}
        setOpen={(status) => setOpenUpdateVendor(status)}
        action="update"
      />
      <CreateCustomer
        open={openUpdateCustomer}
        setOpen={(status) => setOpenUpdateCustomer(status)}
        action="update"
      />
    </>
  );
}
