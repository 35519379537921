import { createTheme } from '@mui/material/styles';

export const appTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          .pac-container {
            z-index: 1500 !important;
            min-width: 400px !important;
          },
          .MuiFormControl-root {
            padding-bottom: 0 !important;
          }
        `
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    },
    body2: {
      fontSize: '15px'
    },
    body1: {
      fontSize: '15px'
    }
  },
  palette: {
    primary: {
      main: '#c32051' // red
    },
    info: {
      main: '#4e4e4e' // gray
    },
    secondary: {
      main: '#335d87' // blue
    }
  }
});
