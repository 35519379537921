import { getBillById } from 'api/adminHub';
import EditIcon from 'assets/images/comments-icon.svg';
import DeleteIcon from 'assets/images/delete-icon.svg';
import Visibility from 'assets/images/visibility.svg';
import DataTable from 'components/NewLayout/Table';
import BillUpdateModal from 'pages/PaymentWorkspace/component/BillUpdateModal';
import { useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { useAdminWorkspaceStore } from 'store/index';
import QBMessageModal from './QBMessageModal';
interface Bill {
  bill_id?: number;
}
export default function BillAlerts({ onClickDelete }: { onClickDelete: (id: string) => void }) {
  const qbAlerts = useAdminWorkspaceStore((state) => state.qbAlerts);
  const [selectedMsg, setSelectedMsg] = useState<string>('');
  const [showMsgModal, setShowMsgModal] = useState<boolean>(false);
  const [bill, setBill] = useState<Bill>({});
  const [loading, setLoading] = useState<boolean>(false);
  const bilAlerts = qbAlerts.filter((a) => ['UPDATE_BILL', 'CREATE_BILL'].includes(a.action_type));

  const columns = [
    {
      prop: 'action_type',
      name: 'Action Type',
      data: 'action_type'
    },
    {
      prop: 'oneXerp_id',
      name: 'Bill Id',
      data: 'oneXerp_id'
    },
    {
      prop: 'vendor_name',
      name: 'Vendor Name',
      data: (row) => row.message?.body?.VendorName ?? ''
    },
    {
      prop: 'account_name',
      name: 'Account Name',
      data: (row) => row.message?.body?.AccountName ?? ''
    },
    {
      prop: 'last_updated_at',
      name: 'Last Updated',
      data: 'last_updated_at',
      type: 'date',
      dateFormat: 'date'
    },
    {
      prop: 'action',
      name: 'Action',
      data: (row) => (
        <>
          <img
            src={Visibility}
            width="30px"
            onClick={() => {
              setSelectedMsg(row);
              setShowMsgModal(true);
            }}
            className="img-fluid link-icon"
            alt="Visibility"
            title="View Message Details"
          />
          <img
            src={EditIcon}
            width="30px"
            onClick={() => onClickEdit(row)}
            className="img-fluid link-icon"
            alt="Edit Icon"
            title="Edit Bill"
          />
          <img
            onClick={() => onClickDelete(row?.oneXerp_id)}
            src={DeleteIcon}
            className={'img-fluid link-icon'}
            alt="Del Icon"
          />
        </>
      )
    }
  ];

  const onClickEdit = (message) => {
    setLoading(true);
    getBillById(message?.oneXerp_id)
      .then((res) => {
        if (res.bill_id) {
          const bill = { ...res, actionType: message.action_type };
          setBill(bill);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return loading ? (
    <div style={{ width: '100%', margin: '5% auto', textAlign: 'center' }}>
      <RotatingLines
        strokeColor="grey"
        strokeWidth="2"
        animationDuration="0.99"
        width="60"
        visible={true}
      />
    </div>
  ) : (
    <>
      <DataTable items={bilAlerts} columns={columns} />
      {showMsgModal && (
        <QBMessageModal onClose={() => setShowMsgModal(false)} message={selectedMsg} />
      )}
      {bill.bill_id && (
        <BillUpdateModal
          billId={bill.bill_id}
          onClose={() => setBill({})}
          onComplete={() => console.log('done')}
        />
      )}
    </>
  );
}
