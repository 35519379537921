import CustomDialog from 'components/NewLayout/Dialog';

export default function QBMessageModal({
  onClose,
  message
}: {
  onClose: () => void;
  message: string;
}) {
  return (
    <CustomDialog
      open={true}
      loading={false}
      maxWidth="md"
      closeDialog={() => onClose()}
      title="Quickbooks Message"
      content={
        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', textAlign: 'justify' }}>
          {JSON.stringify(message, null, 2)}
        </pre>
      }
    />
  );
}
