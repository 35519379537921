import type { AssignedTransportationRequest } from 'api/types';
import type {
  PurchaseOrderTransportationRequest,
  TransportationTrip
} from 'api/types/driverTransportationWorkSpace';
import { removeDuplicatesFromArray } from 'utils';
import type { StateCreator } from 'zustand';

export interface TransportationTripsSlice {
  assignedTransportationTrips: AssignedTransportationRequest[];
  transportationTrips: TransportationTrip[];
  addTransportationTrips: (trips: TransportationTrip[]) => void;
  removeTransportationTrip: (transportation_trip_id: string) => void;
  updateTransportationTripData: (trips: TransportationTrip) => void;
  addAssignedTransportationTrips: (trips: AssignedTransportationRequest[]) => void;
  addAllAssignedTransportationTrips: (trips: AssignedTransportationRequest[]) => void;
  removeAssignedTransportationTrip: (transportation_trip_id: string) => void;
  updateAssignedTransportationTrip: (trips: AssignedTransportationRequest) => void;
  updatePurchaseOrderTransportationRequestData: (
    transportation_trip_id: string,
    request: PurchaseOrderTransportationRequest
  ) => void;
  removePurchaseOrderTransportationRequestData: (
    transportation_trip_id: string,
    purchase_order_transportation_request_id: string
  ) => void;
  removeTripStop: (purchase_order_transportation_request_id: string) => void;
  addTripStop: (transportation_trip_id: string, stop: PurchaseOrderTransportationRequest) => void;
}

export const transportationTripsSlice: StateCreator<TransportationTripsSlice> = (set) => ({
  transportationTrips: [],
  assignedTransportationTrips: [],
  addTransportationTrips: (trips: TransportationTrip[]) =>
    set((state: TransportationTripsSlice) => ({
      transportationTrips: removeDuplicatesFromArray(
        [...trips, ...state.transportationTrips],
        'transportation_trip_id'
      )
    })),
  removeTransportationTrip: (transportation_trip_id: string) =>
    set((state: TransportationTripsSlice) => ({
      transportationTrips: state.transportationTrips.filter(
        (request) => request.transportation_trip_id !== transportation_trip_id
      )
    })),
  updateTransportationTripData: (trip: TransportationTrip) =>
    set((state: TransportationTripsSlice) => ({
      transportationTrips: state.transportationTrips.map((transportationTrip) => {
        if (transportationTrip.transportation_trip_id === trip.transportation_trip_id) {
          return {
            ...transportationTrip,
            ...trip
          };
        } else {
          return transportationTrip;
        }
      })
    })),
  addAssignedTransportationTrips: (trips: AssignedTransportationRequest[]) =>
    set((state: TransportationTripsSlice) => ({
      assignedTransportationTrips: removeDuplicatesFromArray(
        [...trips, ...state.assignedTransportationTrips],
        'transportation_trip_id'
      )
    })),
  addAllAssignedTransportationTrips: (
    assignedTransportationTrips: AssignedTransportationRequest[]
  ) =>
    set(() => ({
      assignedTransportationTrips
    })),
  removeAssignedTransportationTrip: (transportation_trip_id: string) =>
    set((state: TransportationTripsSlice) => ({
      assignedTransportationTrips: state.assignedTransportationTrips.filter(
        (request) => request.transportation_trip_id !== transportation_trip_id
      )
    })),
  updateAssignedTransportationTrip: (trip: AssignedTransportationRequest) =>
    set((state: TransportationTripsSlice) => ({
      assignedTransportationTrips: state.assignedTransportationTrips.map((transportationTrip) => {
        if (transportationTrip.transportation_trip_id === trip.transportation_trip_id) {
          return {
            ...transportationTrip,
            ...trip
          };
        } else {
          return transportationTrip;
        }
      })
    })),
  updatePurchaseOrderTransportationRequestData: (
    transportation_trip_id,
    request: PurchaseOrderTransportationRequest
  ) =>
    set((state: TransportationTripsSlice) => ({
      transportationTrips: state.transportationTrips.map((trip) => {
        if (trip.transportation_trip_id === transportation_trip_id) {
          const updatedRequests = trip.purchase_order_transportation_requests?.map((req) => {
            if (
              req.purchase_order_transportation_request_id ===
              request.purchase_order_transportation_request_id
            ) {
              return { ...req, ...request };
            } else {
              return req;
            }
          });
          return { ...trip, purchase_order_transportation_requests: updatedRequests };
        } else {
          return trip;
        }
      })
    })),
  removePurchaseOrderTransportationRequestData: (
    transportation_trip_id,
    purchase_order_transportation_request_id
  ) =>
    set((state: TransportationTripsSlice) => ({
      transportationTrips: state.transportationTrips.map((trip) => {
        if (trip.transportation_trip_id === transportation_trip_id) {
          const updatedRequests = trip.purchase_order_transportation_requests?.filter(
            (req) =>
              req.purchase_order_transportation_request_id !==
              purchase_order_transportation_request_id
          );
          return { ...trip, purchase_order_transportation_requests: updatedRequests };
        } else {
          return trip;
        }
      })
    })),
  removeTripStop: (purchase_order_transportation_request_id) =>
    set((state: TransportationTripsSlice) => ({
      assignedTransportationTrips: state.assignedTransportationTrips.map((trip) => {
        const updatedStops = trip.subRows?.filter(
          (stop) =>
            stop.purchase_order_transportation_request_id !==
            purchase_order_transportation_request_id
        );
        return { ...trip, subRows: updatedStops, totalstops: updatedStops?.length?.toString() };
      })
    })),
  addTripStop: (transportation_trip_id: string, stop: PurchaseOrderTransportationRequest) =>
    set((state: TransportationTripsSlice) => ({
      assignedTransportationTrips: state.assignedTransportationTrips.map((trip) => {
        if (trip.transportation_trip_id == transportation_trip_id) {
          return {
            ...trip,
            totalstops: trip.subRows.length + 1,
            subRows: [...(trip.subRows ?? []), stop]
          };
        } else {
          return trip;
        }
      })
    }))
});
