import type { PurchaseOrderTransportationRequest } from 'api/types';
// import { removeDuplicatesFromArray } from 'utils';
import type { StateCreator } from 'zustand';

export interface TransportationRequestSlice {
  transportationRequests: PurchaseOrderTransportationRequest[];
  addPurchaseOrderTransportationRequest: (orders: PurchaseOrderTransportationRequest[]) => void;
  removePurchaseOrderTransportationRequest: (
    purchase_order_transportation_request_id: string
  ) => void;
  updatePurchaseOrderTransportationRequest: (orders: PurchaseOrderTransportationRequest) => void;
}

export const transportationRequestSlice: StateCreator<TransportationRequestSlice> = (set) => ({
  transportationRequests: [],
  addPurchaseOrderTransportationRequest: (
    transportationRequests: PurchaseOrderTransportationRequest[]
  ) =>
    set(() => ({
      transportationRequests
    })),
  removePurchaseOrderTransportationRequest: (purchase_order_transportation_request_id: string) =>
    set((state: TransportationRequestSlice) => ({
      transportationRequests: state.transportationRequests.filter(
        (request) =>
          request.purchase_order_transportation_request_id !==
          purchase_order_transportation_request_id
      )
    })),
  updatePurchaseOrderTransportationRequest: (request: PurchaseOrderTransportationRequest) =>
    set((state: TransportationRequestSlice) => ({
      transportationRequests: state.transportationRequests.map((transportationRequest) => {
        if (
          transportationRequest.purchase_order_transportation_request_id ===
          request.purchase_order_transportation_request_id
        ) {
          return {
            ...transportationRequest,
            ...request
          };
        } else {
          return transportationRequest;
        }
      })
    }))
});
