import type { Validator } from 'api/types';
import ErrorMessage from 'components/NewLayout/ErrorMessage';
import { useEffect, useRef } from 'react';
import Select from 'react-select';

export interface DropdownOptionType {
  value: string;
  id: string | number;
  color?: string;
  label?: string;
  vendor_id?: string;
}
type SelectElement = HTMLSelectElement & {
  onMenuOpen?: () => void;
};

export default function DropDown({
  labelText,
  name,
  value,
  data,
  onChange,
  isRequired,
  className,
  optionText,
  disabled,
  validator,
  validationString,
  onBlur,
  onKeyDown,
  isDropdownOptionListVisible
}: {
  labelText?: string;
  name: string;
  value?: string | number;
  onChange: (event: { target: { name: string; value: string } }) => void;
  data?: DropdownOptionType[];
  isRequired?: boolean | string;
  className?: string;
  optionText?: string;
  disabled?: boolean;
  validator?: Validator;
  validationString?: string;
  onBlur?: (event: { target: { name: string; value: string } }) => void;
  onKeyDown?: null;
  isDropdownOptionListVisible?: boolean;
}) {
  const selectRef = useRef<SelectElement | null>(null);

  useEffect(() => {
    if (isDropdownOptionListVisible && selectRef.current) {
      selectRef.current.focus();
      selectRef.current.onMenuOpen();
    }
  }, [isDropdownOptionListVisible]);

  const selectOptions = data?.map((option) => ({
    value: option.id,
    label: option.value,
    color: option.color
  }));

  const isError = validator?.current?.message(
    name,
    value?.toString(),
    validationString || 'required'
  );
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.color ?? '',
      color: state.data.color ? '#fff' : '#000',
      textAlign: 'left'
    })
  };

  const handleChange = (selectedOption) => {
    onChange({ target: { name, value: selectedOption.value } });
  };

  return (
    <>
      {labelText == undefined ? (
        ''
      ) : (
        <label className="form-label">
          {labelText}
          {isRequired != false ? <span className="required">*</span> : ''}
        </label>
      )}
      <Select
        ref={selectRef}
        name={name}
        className={`${className} ${isRequired && isError ? 'red-border' : ''} react-select`}
        onChange={handleChange}
        getOptionLabel={(option) => option.label}
        value={
          selectOptions && value ? selectOptions.find((option) => option.value == value) : null
        }
        options={
          name.includes('project') ? selectOptions : sortDropdownOptions(selectOptions, 'value')
        }
        isDisabled={disabled}
        placeholder={optionText || 'Select an option'}
        noOptionsMessage={() => 'No options available'}
        styles={customStyles}
        onBlur={onBlur}
        {...(onKeyDown ? { onKeyDown } : '')}
        menuIsOpen={isDropdownOptionListVisible}
      />
      {isRequired && isError ? (
        <ErrorMessage
          validator={validator}
          value={value}
          label={name}
          validationString={validationString || ''}
        />
      ) : (
        <p />
      )}
    </>
  );
}

export function sortDropdownOptions(options, field = 'id') {
  return options?.sort(function (a, b) {
    if (a[field] && b[field]) {
      const valueA = a[field === 'id' ? 'value' : 'label'].toLowerCase();
      const valueB = b[field === 'id' ? 'value' : 'label'].toLowerCase();
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    }
  });
}
