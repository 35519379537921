import React from 'react';
import { Typography } from '@mui/material';
import type { Validator } from 'api/types';
interface IErrorMessageProps {
  validator: Validator;
  label: string;
  value: string | number | undefined;
  validationString?: string;
}

export default function ErrorMessage(props: IErrorMessageProps) {
  const { validator, label, value, validationString } = props;
  return (
    <Typography variant="body2" color="error" style={{ textAlign: 'left' }}>
      {validator?.current?.message(
        label,
        String(value),
        validationString ? validationString : 'required'
      )}
    </Typography>
  );
}
