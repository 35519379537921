import { Box, Typography } from '@mui/material';
import type { EstimateItem, Estimate, Zone } from 'api/types';
import PaperContainer from 'components/PaperContainer';
import type { PaginationDataType } from '..';
import AccordionTable from './Accordion';
import EstimateItemSearchHeader from './EstimateItemSearchHeader';
import HeaderSlider from './HeaderSlider';
import NoDocuments from './NoDocuments';
import CustomButton from 'components/NewLayout/Button';

export type ColumnType = {
  prop: string;
  name: string;
  data: string | ((row) => string | JSX.Element);
};

const EstimateItems = ({
  isLoading,
  setAddEstimateItemModal,
  columns,
  items,
  estimateData,
  setPaginationData,
  handleHeaderValue,
  headerValues
}: {
  isLoading: boolean;
  setAddEstimateItemModal: (val: boolean) => void;
  columns: ColumnType[];
  items: EstimateItem[];
  estimateData: Estimate;
  setPaginationData: (data: PaginationDataType) => void;
  headerValues: { [key: string]: number };
  handleHeaderValue: (id: string, value: number) => void;
}) => (
  <>
    <Box sx={{ marginY: '20px' }}>
      <Typography
        variant="h5"
        sx={{
          fontFamily: 'Poppins',
          fontWeight: '500',
          fontSize: '20px',
          lineHeight: '30px',
          textAlign: 'left',
          textUnderlinePosition: 'from-font',
          textDecorationSkipInk: 'none'
        }}>
        {`${estimateData.job_name} - Estimate Items`}
      </Typography>
    </Box>
    {items.length === 0 ? (
      <Box sx={{ marginY: '15px' }}>
        <NoDocuments
          text={'Add Items to Estimate'}
          component={
            <CustomButton
              label={'Add Items'}
              onClick={() => setAddEstimateItemModal(true)}
              sx={{ padding: '3px 6px 3px 6px', mt: '8px' }}
            />
          }
        />
      </Box>
    ) : (
      <PaperContainer>
        <Box sx={{ mb: '10px' }}>
          <EstimateItemSearchHeader
            onChange={() => console.log('input is working')}
            items={items}
            setAddEstimateItemModal={setAddEstimateItemModal}
            headerValues={headerValues}
          />
        </Box>
        <Box>
          <HeaderSlider
            handleHeaderValue={(id: string, value: number) => handleHeaderValue(id, value)}
          />
        </Box>
        {estimateData.zones?.map((zone: Zone, zoneIndex) => {
          const zoneFilteredItems = items.filter((item) => item.zone_id === zone.zone_id);
          return zone.scenes && zone.scenes.length > 0
            ? zone.scenes.map((scene, sceneIndex) => {
                const sceneFilteredItems = zoneFilteredItems.filter(
                  (item) => item.scene_id === scene.scene_id
                );
                // if (sceneFilteredItems.length === 0) return null;
                return (
                  <AccordionTable
                    key={`${zoneIndex}-${sceneIndex}`}
                    zoneName={`Zone ${zoneIndex + 1} - ${zone.zone_name}`}
                    sceneName={scene.scene_name}
                    columns={columns}
                    items={sceneFilteredItems}
                    isLoading={isLoading}
                    dataSource={setPaginationData}
                  />
                );
              })
            : zoneFilteredItems.length > 0 && (
                <AccordionTable
                  key={`zone-${zoneIndex}`}
                  zoneName={`Zone ${zoneIndex + 1} - ${zone.zone_name}`}
                  columns={columns}
                  items={zoneFilteredItems}
                  isLoading={isLoading}
                  dataSource={setPaginationData}
                />
              );
        })}
      </PaperContainer>
    )}
  </>
);

export default EstimateItems;
