import React from 'react';
import PhoneInput from 'react-phone-input-2';
import ErrorMessage from 'components/NewLayout/ErrorMessage';
import 'react-phone-input-2/lib/material.css';
import type { Validator } from 'api/types';

export default function PhoneNumberInput({
  value,
  handleChange,
  disabled,
  isRequired,
  name,
  validator,
  validationString
}: {
  value?: string;
  handleChange: ({
    target: { name, value }
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  disabled?: boolean;
  label?: string;
  isRequired?: boolean;
  name: string;
  validator?: Validator;
  validationString?: string;
}) {
  const isError = validator?.current?.message(name, value, validationString || 'required');
  return (
    <div style={{ position: 'relative' }}>
      <PhoneInput
        country={'us'}
        onlyCountries={['us']}
        onChange={(phone) => handleChange({ target: { name, value: phone } })}
        disabled={disabled}
        value={value}
        inputStyle={{
          width: '100%'
        }}
        specialLabel="Phone Number"
        containerClass={`phone-input ${isRequired && isError ? 'red-border' : ''}`}
      />
      {isRequired && (
        <span
          style={{
            position: 'absolute',
            right: '10px',
            top: '5px',
            color: 'red'
          }}>
          *
        </span>
      )}
      {isRequired && isError ? (
        <ErrorMessage
          validator={validator}
          value={value}
          label={name}
          validationString={validationString || ''}
        />
      ) : (
        <p />
      )}
    </div>
  );
}
