import { Checkbox, FormControlLabel, Grid, Typography, useTheme } from '@mui/material';
import { getAllCustomers, getAllUsers, postProject, updateProject } from 'api';
import type { Customer, CustomerContactItems, Project, User } from 'api/types';
import type { DropdownOptionType } from 'components/inputs/Dropdown';
import CustomButton from 'components/NewLayout/Button';
import CustomDialog from 'components/NewLayout/Dialog';
import DrawerForm from 'components/NewLayout/DrawerForm';
import DropDown from 'components/NewLayout/Dropdown';
import Loader from 'components/NewLayout/Loader';
import MultiSelect from 'components/NewLayout/MultiSelect';
import TextField from 'components/NewLayout/Textfield';
import type { ChangeEvent } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useReducer, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Validator from 'simple-react-validator';
import { useAdminWorkspaceStore } from 'store/index';
import { nameMaxLength } from 'utils/constants';
import {
  getValidations,
  JOB_NAME_VALIDATION_REGEX,
  MAX_11_DIGITS_TWO_DECIMALS_REGEX,
  PERCENTAGE_VALIDATION_REGEX,
  removeNullOrEmptyValues,
  sortArrByKey
} from 'utils/index';
import type { AdminHubChildComponentRef } from 'utils/types';

export interface SelectDropdownOptionType {
  id: string;
  value: string;
}

export default function CreateProject({
  open,
  setOpen,
  action,
  projectManagerUsers
}: {
  open: boolean;
  setOpen: (status: boolean) => void;
  action: 'create' | 'update';
  projectManagerUsers: DropdownOptionType[];
}) {
  const childRef = useRef<AdminHubChildComponentRef>();
  return (
    <DrawerForm
      open={open}
      closeDrawer={() => setOpen(false)}
      heading={`${action === 'create' ? 'Create' : 'Update'}  A Job`}
      actions={
        <>
          <CustomButton variant="outlined" label="Discard" onClick={() => setOpen(false)} />
          <CustomButton
            label="Submit Request"
            onClick={() => {
              if (childRef.current) {
                childRef.current.submitForm();
              }
            }}
          />
        </>
      }>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography component="em" color="textSecondary">
            Enter the details below to create a new Job.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ProjectForm
            action={action}
            ref={childRef}
            onComplete={() => setOpen(false)}
            projectManagerUsers={projectManagerUsers}
          />
        </Grid>
      </Grid>
    </DrawerForm>
  );
}

const initialObj = {
  project_completion: '',
  project_name: '',
  project_manager: '',
  customer_id: '',
  is_active: true,
  created_by: '',
  last_updated_by: '',
  created_at: '',
  last_updated_at: '',
  project_code: '',
  customer_contact_ids: [],
  appearance_order_and_logistic: true,
  appearance_time_tracting_and_attendance: true,
  fabrication_hours: 0,
  pm_hours: 0,
  design_hours: 0,
  paint_hours: 0,
  media_hours: 0,
  install_hours: 0,
  estimated_hours: 0,
  sell_price: 0,
  start_date: '',
  end_date: '',
  projected_end_date: ''
};

const ProjectForm = forwardRef(
  (
    {
      action,
      onComplete,
      projectManagerUsers
    }: { action: 'update' | 'create'; onComplete: () => void; projectManagerUsers },
    ref
  ) => {
    const [data, setData] = useState<Project>(initialObj);
    const [allCustomers, setAllCustomers] = useState<DropdownOptionType[]>([]);
    const [allCustomersDetails, setAllCustomersDetails] = useState<Customer[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [allContacts, setAllContacts] = useState<SelectDropdownOptionType[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SelectDropdownOptionType[]>([]);
    const customerContactIdArr: string[] = [];
    const [warningMessage, setWarningMessage] = useState<string>('');
    const { messages, validators } = getValidations();
    const validator = useRef(
      new Validator({
        messages: {
          ...messages,
          size: 'Length should not exceeded 4 digits'
        },
        validators
      })
    );
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const project = useAdminWorkspaceStore((state) => state.selectedJob);
    const updateStoreJob = useAdminWorkspaceStore((state) => state.updateJob);
    const theme = useTheme();

    useEffect(() => {
      if (project) {
        setData({
          ...project,
          estimated_hours: sumOfEstimatedHours(project)
        });
      } else {
        setData(initialObj);
      }

      if (action == 'update') {
        getDropdownOptions(project?.customer_id?.toString() ?? '', true);
      }
    }, [project, allCustomersDetails]);

    useEffect(() => {
      setIsLoading(true);
      Promise.all([getAllCustomers()])
        .then(([customersResponse]) => {
          const customersArr: DropdownOptionType[] = customersResponse.map((item: Customer) => ({
            id: item.customer_id ?? '',
            value: item?.customer_name ?? ''
          }));

          setAllCustomersDetails(customersResponse);
          setAllCustomers(customersArr);
        })
        .catch((error) => {
          console.error('Error fetching users or customers:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, []);

    useEffect(() => {
      if (project) {
        const formattedStartDate = project.start_date ? formatDateForInput(project.start_date) : '';
        const formattedEndDate = project.projected_end_date
          ? formatDateForInput(project.projected_end_date)
          : '';

        setData({
          ...project,
          start_date: formattedStartDate,
          projected_end_date: formattedEndDate,
          estimated_hours: sumOfEstimatedHours(project)
        });
      } else {
        setData(initialObj);
      }

      if (action == 'update') {
        getDropdownOptions(project?.customer_id?.toString() ?? '', true);
      }
    }, [project, allCustomersDetails]);

    const formatDateForInput = (dateString) => {
      if (!dateString) return '';
      let date;
      try {
        date = new Date(dateString);
        if (isNaN(date.getTime())) return '';
        return date.toISOString().split('T')[0];
      } catch (error) {
        console.error('Error formatting date:', error);
        return '';
      }
    };

    useImperativeHandle(ref, () => ({
      submitForm() {
        if (validator.current.allValid()) {
          setIsLoading(true);
          if (action === 'create') {
            toast.promise(
              postProject(removeNullOrEmptyValues(data) as Project).then((res) => {
                if (res.message === 'Project added successfully!') {
                  onComplete();
                }
              }),
              {
                loading: 'Adding...',
                success: 'Job added successfully!',
                error: ''
              },
              { position: 'top-center', success: { duration: 3000 }, id: 'create_success' }
            );
          } else {
            const toastId = toast.loading('Loading...', {
              position: 'top-center'
            });
            toastId;
            updateProject(data, Number(data?.project_id) || 0)
              .then((res) => {
                if (res.message === 'Project updated successfully!') {
                  onComplete();
                  const customer = allCustomersDetails.find(
                    (a) => a.customer_id == data.customer_id
                  );
                  let customerContacts;
                  if (customer) {
                    customerContacts =
                      customer?.customercontacts?.filter((contact) =>
                        data.customer_contact_ids?.includes(contact?.customer_contact_id || '')
                      ) || [];
                  }
                  updateStoreJob({
                    ...data,
                    customerName: customer?.customer_name || '',
                    customer_contacts: customerContacts,
                    subRows: customerContacts,
                    project_manager: data.project_manager,
                    project_manager_name: projectManagerUsers.find(
                      (pm) => pm.id === data.project_manager
                    )?.value
                  });
                }
                toast.dismiss(toastId);
                toast.success('Job updated successfully!!', {
                  position: 'top-center',
                  id: 'update_success'
                });
                setIsLoading(false);
              })
              .catch(() => {
                toast.dismiss(toastId);
              });
          }
        } else {
          validator.current.showMessages();
          forceUpdate();
        }
      }
    }));

    function getDropdownOptions(param: string, isFirstRender?: boolean) {
      const contactsArr: SelectDropdownOptionType[] = [];
      const filteredContactsArr: SelectDropdownOptionType[] = [];
      const newArray = allCustomersDetails.filter(function (el) {
        return el.customer_id == param;
      });

      newArray[0]?.customercontacts?.map((item: CustomerContactItems) => {
        contactsArr.push({
          id: item.customer_contact_id ?? '0',
          value: item.first_name ?? '' + item.last_name
        });
      });
      setAllContacts(contactsArr);
      if (isFirstRender) {
        project?.customer_contacts?.map((item: CustomerContactItems) => {
          filteredContactsArr.push({
            id: item.customer_contact_id ?? '0',
            value: item.first_name ?? '' + item.last_name
          });
        });
        setSelectedOptions(filteredContactsArr);
      }
    }

    const handleChangeMultiSelect = ({ target: { value } }) => {
      setSelectedOptions(value);
      value.map((item) => {
        customerContactIdArr.push(item?.id);
      });

      setData({ ...data, customer_contact_ids: customerContactIdArr });
    };

    const handleChange = ({
      target: { name, value }
    }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (name === 'material_budget' || name === 'labor_budget') {
        if (MAX_11_DIGITS_TWO_DECIMALS_REGEX.test(value)) {
          setData((prevData) => ({
            ...prevData,
            [name]: value
          }));
        }
        return;
      }
      if (name === 'project_completion') {
        if (PERCENTAGE_VALIDATION_REGEX.test(value)) {
          setData((prevData) => ({
            ...prevData,
            [name]: value
          }));
        }
        return;
      }

      if (name === 'project_name' && !JOB_NAME_VALIDATION_REGEX.test(value)) {
        return;
      }

      if (name === 'customer_id') {
        setAllContacts([]);
        getDropdownOptions(value);
        setSelectedOptions([]);
        setData((prevData) => ({
          ...prevData,
          [name]: value
        }));
        return;
      }

      if (name === 'project_code' || name === 'labor_budget' || name === 'material_budget') {
        if (name === 'project_code' && value.length > 4) {
          return;
        }
        if (Number(value) < 0) {
          return;
        }
      }

      if (
        name === 'fabrication_hours' ||
        name === 'pm_hours' ||
        name === 'design_hours' ||
        name === 'paint_hours' ||
        name === 'media_hours' ||
        name === 'install_hours'
      ) {
        if (MAX_11_DIGITS_TWO_DECIMALS_REGEX.test(value) || value == '') {
          const updatedData = { ...data, [name]: Number(value) };

          setData({ ...updatedData, estimated_hours: sumOfEstimatedHours(updatedData) });
        }
        return;
      }

      setData({ ...data, [name]: value === 'true' ? true : value === 'false' ? false : value });
    };

    const onChangeAppearance = (
      name: 'appearance_order_and_logistic' | 'appearance_time_tracting_and_attendance',
      value: boolean
    ) => {
      if (!value) {
        setWarningMessage(
          name === 'appearance_order_and_logistic'
            ? 'Are you sure, you want to unchecked this? This job will not be available in Order and Logistics modules.'
            : 'Are you sure, you want to unchecked this? This job will not be available in Time tracking and attendance modules.'
        );
      } else {
        setData({
          ...data,
          [name]: value
        });
      }
    };

    const textFieldConfigs = [
      {
        labelText: 'Fabrication Hours',
        name: 'fabrication_hours',
        placeholder: 'Fabrication Name',
        value: data?.fabrication_hours
      },
      { labelText: 'PM Hours', name: 'pm_hours', placeholder: 'PM Hours', value: data?.pm_hours },
      {
        labelText: 'Design Hours',
        name: 'design_hours',
        placeholder: 'Design Hours',
        value: data?.design_hours
      },
      {
        labelText: 'Paint Hours',
        name: 'paint_hours',
        placeholder: 'Paint Hours',
        value: data?.paint_hours
      },
      {
        labelText: 'Media Hours',
        name: 'media_hours',
        placeholder: 'Media Hours',
        value: data?.media_hours
      },
      {
        labelText: 'Install Hours',
        name: 'install_hours',
        placeholder: 'Install Hours',
        value: data?.install_hours
      }
    ];

    const sumOfEstimatedHours = (project: Project) => {
      const estimatedHours =
        (Number(project.fabrication_hours) ?? 0) +
        (Number(project.pm_hours) ?? 0) +
        (Number(project.design_hours) ?? 0) +
        (Number(project.paint_hours) ?? 0) +
        (Number(project.media_hours) ?? 0) +
        (Number(project.install_hours) ?? 0);
      return Number(estimatedHours.toFixed(2));
    };

    return (
      <>
        {' '}
        {isLoading ? (
          <Loader />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                labelText="Project Completion %"
                name="project_completion"
                placeholder="Project Completion %"
                value={data?.project_completion}
                onChange={handleChange}
                disabled={action === 'update' && !project}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                labelText="Job Name"
                name="project_name"
                placeholder="Project Name"
                value={data.project_name}
                onChange={handleChange}
                isRequired={true}
                disabled={action === 'update' && !project}
                validator={validator}
                maxLength={nameMaxLength}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                min="0"
                maxLength={4}
                labelText="Job Number"
                name="project_code"
                placeholder="Project Number"
                value={data.project_code}
                onChange={handleChange}
                isRequired={true}
                disabled={action === 'update' && !project}
                validator={validator}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DropDown
                label="Project Manager"
                inputName="project_manager"
                optionText="Select Project Manager Name"
                value={data.project_manager ?? ''}
                required
                onChange={handleChange}
                options={sortArrByKey(projectManagerUsers, 'value')}
                disabled={action === 'update' && !project}
                validator={validator}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DropDown
                label="Customer"
                inputName="customer_id"
                optionText="Select Customer Name"
                value={data.customer_id}
                required
                onChange={handleChange}
                options={sortArrByKey(allCustomers, 'value')}
                disabled={action === 'update' && !project}
                validator={validator}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MultiSelect
                label="Customer Contact"
                inputName="customer_contact_ids"
                selectedOptions={selectedOptions}
                onChange={handleChangeMultiSelect}
                options={allContacts}
                disabled={action === 'update' && !project}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                labelText="Material Budget"
                name="material_budget"
                placeholder="Material Budget"
                value={data.material_budget}
                onChange={handleChange}
                validator={validator}
                isRequired
                disabled={action === 'update' && !project}
                type="number"
                min="0"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                labelText="Sell Price"
                name="labor_budget"
                placeholder="Sell Price"
                value={data.labor_budget}
                onChange={handleChange}
                disabled={action === 'update' && !project}
                type="number"
                min="0"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                labelText="Start Date"
                name={`start_date`}
                value={data?.start_date ?? ''}
                onChange={handleChange}
                type="date"
                size="medium"
                validator={validator}
                isRequired
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                labelText="Projected End Date"
                name={`projected_end_date`}
                value={data?.projected_end_date ?? ''}
                onChange={handleChange}
                type="date"
                size="medium"
                validator={validator}
                isRequired
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                labelText="End Date"
                name={`end_date`}
                value={data?.end_date ?? ''}
                onChange={handleChange}
                type="date"
                size="medium"
              />
            </Grid>
            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={({ target: { checked } }) =>
                      onChangeAppearance('appearance_time_tracting_and_attendance', checked)
                    }
                    checked={data.appearance_time_tracting_and_attendance}
                  />
                }
                sx={{
                  '& .MuiFormControlLabel-label': {
                    color: theme.palette.secondary.main
                  }
                }}
                label="Appears in Time Tracking"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={({ target: { checked } }) =>
                      onChangeAppearance('appearance_order_and_logistic', checked)
                    }
                    checked={data.appearance_order_and_logistic}
                  />
                }
                sx={{
                  '& .MuiFormControlLabel-label': {
                    color: theme.palette.secondary.main
                  }
                }}
                label="Appears in Ordering & Logistics"
              />
            </Grid>

            <fieldset
              style={{
                borderColor: 'black',
                borderWidth: '2px',
                borderStyle: 'solid',
                padding: '16px',
                marginTop: '45px'
              }}>
              <legend style={{ fontWeight: 'bold', fontSize: '1rem' }}>Estimated Hours</legend>
              <Grid container spacing={2}>
                <>
                  {textFieldConfigs.map((config, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <TextField
                        type="number"
                        min="0"
                        maxLength={4}
                        labelText={config.labelText}
                        name={config.name}
                        placeholder={config.placeholder}
                        value={String(config.value ?? 0)}
                        onChange={handleChange}
                      />
                    </Grid>
                  ))}
                </>
                <Grid item xs={12} sm={6} />
                <Grid item xs={12} sm={6}>
                  <TextField
                    labelText="Total Hours"
                    name="estimated_hours"
                    placeholder="Total Hours"
                    value={String(data?.estimated_hours ?? 0)}
                    disabled
                  />
                </Grid>
              </Grid>
            </fieldset>

            {action === 'update' && (
              <Grid item xs={12} sx={{ mt: '25px' }}>
                <DropDown
                  label="Active Status"
                  inputName="is_active"
                  optionText="Choose..."
                  value={data?.is_active ? 'true' : 'false'}
                  onChange={handleChange}
                  options={[
                    { id: 'true', value: 'Active' },
                    { id: 'false', value: 'Inactive' }
                  ]}
                  disabled={action === 'update' && !project}
                />
              </Grid>
            )}
          </Grid>
        )}
        <CustomDialog
          title={'Confirmation'}
          maxWidth="xs"
          open={warningMessage ? true : false}
          closeDialog={() => setWarningMessage('')}
          content={<Typography>{warningMessage}</Typography>}
          actions={
            <>
              <CustomButton
                onClick={() => setWarningMessage('')}
                variant="outlined"
                label="Cancel"
              />
              <CustomButton
                onClick={() => {
                  let name = '';
                  if (warningMessage.includes('Time tracking and attendance')) {
                    name = 'appearance_time_tracting_and_attendance';
                  } else {
                    name = 'appearance_order_and_logistic';
                  }
                  setWarningMessage('');
                  setData({
                    ...data,
                    [name]: false
                  });
                }}
                label={'Confirm'}
              />
            </>
          }
        />
      </>
    );
  }
);
ProjectForm.displayName = 'ProjectForm';
