import { Typography } from '@mui/material';
import React from 'react';

export default function Badge({
  type,
  label
}: {
  label: string | undefined;
  type: 'success' | 'failed' | 'pending';
}) {
  const colors = {
    success: '#84d4b9',
    failed: '#d08aa1',
    pending: '#819bb5'
  };
  return (
    <Typography
      sx={{
        width: 'max-content',
        borderRadius: '20px',
        padding: '3px 20px',
        background: colors[type],
        color: '#fff'
      }}>
      {label}
    </Typography>
  );
}
