import type { InputBaseComponentProps } from '@mui/material';
import { TextField } from '@mui/material';
import type { Validator } from 'api/types';
import type { ChangeEvent, KeyboardEvent } from 'react';
import React, { useEffect, useRef } from 'react';
import { getMaxCurrentWeekDate } from 'utils/index';
import ErrorMessage from './ErrorMessage';

export default function CustomTextfield({
  labelText,
  name,
  value,
  onChange,
  selectText,
  isRequired,
  placeholder,
  onKeyPress,
  type,
  disabled,
  maxLength,
  id,
  min,
  max,
  validator,
  className,
  validationString,
  dontDisplayErrorMsg,
  isTextarea,
  onBlur,
  InputProps,
  size,
  rows = 5,
  padding,
  autoFocus,
  prefix,
  multiple,
  fileType,
  focused,
  disableFutureDates,
  restrictToCurrentWeek,
  endAdornment
}: {
  labelText?: string;
  name: string;
  value?: string;
  onChange?: ({
    target: { name, value }
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  selectText?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  onKeyPress?: (e: KeyboardEvent) => void;
  type?: 'text' | 'number' | 'date' | 'file' | 'time';
  disabled?: boolean;
  maxLength?: number;
  id?: string;
  min?: string;
  max?: string;
  validator?: Validator;
  className?: string;
  validationString?: string;
  dontDisplayErrorMsg?: boolean;
  isTextarea?: boolean;
  onBlur?: (event: { target: { name: string; value: string } }) => void;
  InputProps?: InputBaseComponentProps;
  size?: 'small' | 'medium';
  padding?: number | string;
  autoFocus?: boolean;
  rows?: number;
  prefix?: React.ReactNode;
  fileType?: string;
  multiple?: boolean;
  focused?: boolean;
  disableFutureDates?: boolean;
  restrictToCurrentWeek?: boolean;
  endAdornment?: React.ReactNode;
}) {
  const isError = isRequired
    ? validator?.current?.message(name, value, validationString ? validationString : 'required')
    : false;

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const handleFocus = (e) => {
    if (selectText) {
      e.currentTarget.select();
    }
  };

  const getMaxDate = () => {
    if (type === 'date') {
      if (restrictToCurrentWeek) {
        return getMaxCurrentWeekDate();
      } else if (disableFutureDates) {
        const today = new Date();
        return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
      }
    }
    return max;
  };

  const maxAttribute = getMaxDate();

  return (
    <>
      <div style={{ position: 'relative' }}>
        {isTextarea ? (
          <TextField
            className={`${className || ''} `}
            error={isRequired && isError}
            placeholder={placeholder ?? ''}
            value={value}
            name={name}
            multiline
            rows={rows}
            fullWidth
            size={size || 'medium'}
            onChange={onChange}
            disabled={disabled}
            label={labelText}
            inputProps={{
              maxLength
            }}
            {...(placeholder
              ? {
                  InputLabelProps: {
                    shrink: true
                  }
                }
              : {})}
            onBlur={onBlur}
            onKeyDown={onKeyPress}
          />
        ) : (
          <TextField
            className={` ${className || ''}`}
            type={type || 'text'}
            error={isRequired && isError}
            value={value}
            size={size || 'medium'}
            label={labelText}
            onChange={onChange}
            onKeyDown={onKeyPress}
            onBlur={onBlur}
            ref={inputRef}
            autoFocus={autoFocus}
            onFocus={handleFocus}
            fullWidth
            focused={focused}
            sx={{ padding: 0 }}
            placeholder={placeholder}
            disabled={disabled ? true : false}
            id={id ?? name}
            name={name}
            inputProps={{
              min,
              max: maxAttribute,
              maxLength,
              style: { padding },
              multiple,
              accept: fileType
            }}
            InputProps={{
              ...InputProps,
              style: { padding },
              startAdornment: prefix ? (
                <span style={{ marginRight: '8px', color: '#888' }}>{prefix}</span>
              ) : null,
              endAdornment: endAdornment ? (
                <span style={{ marginLeft: '8px', color: '#888' }}>{endAdornment}</span>
              ) : null
            }}
            {...(type === 'date' || type === 'time'
              ? {
                  InputLabelProps: {
                    shrink: true
                  }
                }
              : {})}
          />
        )}
        {isRequired && (
          <span
            style={{
              position: 'absolute',
              right: '10px',
              top: '5px',
              color: 'red'
            }}>
            *
          </span>
        )}
      </div>
      {!dontDisplayErrorMsg && isRequired && isError ? (
        <ErrorMessage
          validator={validator}
          value={value}
          label={name}
          validationString={validationString || ''}
        />
      ) : (
        <p />
      )}
    </>
  );
}
