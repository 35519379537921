import { Box, Chip, Grid, Input, Tooltip, Typography } from '@mui/material';
import type { Estimate, PostEstimateInterface, Zone } from 'api/types';
import CreateIcon from 'assets/images/create.svg';
import type { DropdownOptionType } from 'components/inputs/Dropdown';
import CustomButton from 'components/NewLayout/Button';
import DrawerForm from 'components/NewLayout/DrawerForm';
import SearchableDropdown from 'components/NewLayout/Dropdown';
import Loader from 'components/NewLayout/Loader';
import CustomTextfield from 'components/NewLayout/Textfield';
import type { ChangeEvent } from 'react';
import { forwardRef, useImperativeHandle, useReducer, useRef, useState } from 'react';
import Validator from 'simple-react-validator';
import {
  DECIMAL_VALIDATION_TWO_PLACES_REGEX,
  EMAIL_VALIDATION_REGEX,
  getValidations,
  MAX_11_DIGITS_TWO_DECIMALS_REGEX
} from 'utils/index';
import type { AdminHubChildComponentRef } from 'utils/types';
import ZoneHierarchy from './ZoneHierarchy';
import { estimate } from 'api/index';
import AttachmentIcon from 'assets/images/attachment-icon.svg';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import toast from 'react-hot-toast';
import UploadImage from 'components/UploadImage';
import { descriptionMaxLength, nameMaxLength } from 'utils/constants';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';

export default function InitiateEstimateDrawer({
  IsOpen,
  setOpen,
  allCustomers,
  allEstimators,
  setCreateCustomereDrawer,
  successCallBack,
  bidTypes,
  dealSources,
  allDealOwners,
  jobTypes
}: {
  IsOpen: boolean;
  setOpen: (status: boolean) => void;
  allCustomers: DropdownOptionType[];
  allEstimators: DropdownOptionType[];
  setCreateCustomereDrawer: (status: boolean) => void;
  successCallBack: () => void;
  bidTypes: DropdownOptionType[];
  dealSources: DropdownOptionType[];
  allDealOwners: DropdownOptionType[];
  jobTypes: DropdownOptionType[];
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const childRef = useRef<AdminHubChildComponentRef>();

  return (
    <DrawerForm
      open={IsOpen}
      closeDrawer={() => setOpen(false)}
      heading="Initiate an Estimate"
      actions={
        <>
          <CustomButton variant="outlined" label="Discard" onClick={() => setOpen(false)} />
          <CustomButton
            label="Submit Request"
            onClick={() => {
              if (childRef.current) {
                childRef.current.submitForm();
              }
            }}
          />
        </>
      }>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography component="em" color="textSecondary">
            Enter the details below to initiate an estimate.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <Loader />
          ) : (
            <InitiateEstimateForm
              ref={childRef}
              onComplete={() => {
                setOpen(false);
                successCallBack();
              }}
              setIsLoading={setIsLoading}
              allCustomers={allCustomers}
              allEstimators={allEstimators}
              bidTypes={bidTypes}
              dealSources={dealSources}
              allDealOwners={allDealOwners}
              jobTypes={jobTypes}
              setCreateCustomereDrawer={setCreateCustomereDrawer}
              setOpen={setOpen}
            />
          )}
        </Grid>
      </Grid>
    </DrawerForm>
  );
}

const initialObj: Estimate = {
  customer: {
    customer_id: ''
  },
  estimator: {
    user_id: ''
  },
  job_name: '',
  pricing_due_date: '',
  anticipated_start_date: null,
  anticipated_ship_date: null,
  zones: [],
  bid_type: '',
  deal_source: '',
  deal_owner: '',
  job_type: '',
  job_value: '',
  description: '',
  client_contact_name: '',
  client_contact_phone: '',
  client_contact_email: ''
};

const InitiateEstimateForm = forwardRef(
  (
    {
      allCustomers,
      allEstimators,
      onComplete,
      setOpen,
      setCreateCustomereDrawer,
      bidTypes,
      dealSources,
      allDealOwners,
      jobTypes
    }: {
      setIsLoading: (val: boolean) => void;
      allCustomers: DropdownOptionType[];
      allEstimators: DropdownOptionType[];
      onComplete: () => void;
      setOpen: (status: boolean) => void;
      setCreateCustomereDrawer: (status: boolean) => void;
      bidTypes: DropdownOptionType[];
      dealSources: DropdownOptionType[];
      allDealOwners: DropdownOptionType[];
      jobTypes: DropdownOptionType[];
    },
    ref
  ) => {
    const [data, setData] = useState<Estimate>(initialObj);
    const [imageData, setImageData] = useState<{ image: null | File; imageName: string }>({
      image: null,
      imageName: ''
    });
    const [zoneValue, setZoneValue] = useState('');
    const [scenesList, setScenesList] = useState<string[]>([]);
    const [currSceneValue, setCurrSceneValue] = useState('');
    const [editingZoneIndex, setZoneEditingIndex] = useState<number>(-1);
    const [editingSceneIndex, setSceneEditingIndex] = useState<number | null>(null);
    const [editingSceneValue, setSceneEditingValue] = useState('');
    const [zoneSceneList, setZoneSceneList] = useState<Zone[]>([]);
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const validator = useRef(new Validator(getValidations()));

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && currSceneValue.trim() !== '') {
        const isDuplicateScene = scenesList.some(
          (scene) => scene.trim().toLowerCase() === currSceneValue.trim().toLowerCase()
        );

        if (isDuplicateScene) {
          toast.error('Scene name must be unique within the zone.', {
            position: 'top-center'
          });
          return;
        }

        setScenesList((oldState) => [...oldState, currSceneValue.trim()]);
        setCurrSceneValue('');
      }
    };

    const handleChipEditStart = (index: number) => {
      setSceneEditingIndex(index);
      setSceneEditingValue(scenesList[index]);
    };

    const handleEditChange = (e) => {
      setSceneEditingValue(e.target.value);
    };

    const handleEditKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && editingSceneValue.trim() !== '' && editingSceneIndex !== null) {
        const updatedValues = [...scenesList];
        updatedValues[editingSceneIndex] = editingSceneValue.trim();
        setScenesList(updatedValues);
        setSceneEditingIndex(null);
        setSceneEditingValue('');
      } else if (e.key === 'Escape') {
        setSceneEditingIndex(null);
        setSceneEditingValue('');
      }
    };

    const handleEditBlur = () => {
      if (editingSceneValue.trim() !== '' && editingSceneIndex !== null) {
        const updatedValues = [...scenesList];
        updatedValues[editingSceneIndex] = editingSceneValue.trim();
        setScenesList(updatedValues);
      }
      setSceneEditingIndex(null);
      setSceneEditingValue('');
    };

    const handleDelete = (item, index) => {
      const arr = [...scenesList];
      arr.splice(index, 1);
      setScenesList(arr);
    };

    useImperativeHandle(ref, () => ({
      async submitForm() {
        if (validator.current.allValid()) {
          if (
            data.client_contact_email &&
            !EMAIL_VALIDATION_REGEX.test(data.client_contact_email)
          ) {
            toast.error('Please enter a valid email address.', {
              position: 'top-center'
            });
            return;
          }

          if (!zoneSceneList.length) {
            toast.error('Add at least one zone.', {
              position: 'top-center'
            });
            return;
          }

          const sanitizedZoneSceneList = zoneSceneList.map((zone) => ({
            zone_name: zone.zone_name,
            scenes: zone.scenes
              .filter((scene) => typeof scene.scene_name === 'string')
              .map((scene) => ({
                scene_name: scene.scene_name
              }))
          }));

          const finalZoneSceneList = sanitizedZoneSceneList.map((zone) => ({
            ...zone,
            scenes: zone.scenes.length > 0 ? zone.scenes : []
          }));

          if (imageData?.image?.name) {
            const fileName = `${Date.now()}_${imageData.image.name.replaceAll(' ', '-')}`;
            const myNewFile = new File([imageData.image], fileName, {
              type: imageData.image.type
            });
            await UploadImage(myNewFile);
            imageData.imageName = fileName;
          }

          const dataToInsert: PostEstimateInterface = {
            http_method: 'POST',
            data: {
              customer_id: data.customer?.customer_id,
              user_id: data.estimator?.user_id,
              job_name: data.job_name,
              job_size: finalZoneSceneList.some((zone) => zone.scenes.length > 0)
                ? 'Large'
                : 'Small',
              pricing_due_date: data.pricing_due_date,
              anticipated_start_date: data.anticipated_start_date || null,
              anticipated_ship_date: data.anticipated_ship_date || null,
              bid_type: data.bid_type,
              deal_source: data.deal_source,
              deal_owner: data.deal_owner,
              job_type: data.job_type,
              job_value: data.job_value,
              description: data.description,
              client_contact_name: data.client_contact_name,
              client_contact_phone: data.client_contact_phone,
              client_contact_email: data.client_contact_email,
              s3_uri: imageData.imageName,
              zones: finalZoneSceneList
            }
          };

          estimate(dataToInsert)
            .then((res) => {
              onComplete();
              toast.success(res.message, {
                position: 'top-center',
                id: 'update_success'
              });
            })
            .catch((err) => {
              console.error('err', err);
              toast.error(err, {
                position: 'top-center',
                id: 'updated_failed'
              });
            })
            .finally(() => {
              setOpen(false);
            });
        } else {
          validator.current.showMessages();
          forceUpdate();
        }
      }
    }));
    const handlekeyDown = (e: React.KeyboardEvent) => {
      if (e.key === '-' || e.key === '+') {
        e.preventDefault();
      }
    };
    const handleChange = ({
      target: { name, value }
    }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (name === 'zone') {
        setZoneValue(value);
      } else if (name === 'job_value') {
        if (DECIMAL_VALIDATION_TWO_PLACES_REGEX.test(value)) {
          setData((prevData) => ({
            ...prevData,
            [name]: value
          }));
        }
      } else {
        setData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      }
      if (name === 'customer') {
        setData((prevData) => ({
          ...prevData,
          customer: {
            customer_id: value
          }
        }));
      }
      if (name === 'estimator') {
        setData((prevData) => ({
          ...prevData,
          estimator: {
            user_id: value
          }
        }));
      }
    };

    const addZone = () => {
      const isDuplicateZone = zoneSceneList.some(
        (zone) => zone.zone_name.trim().toLowerCase() === zoneValue.trim().toLowerCase()
      );

      if (isDuplicateZone) {
        toast.error('Zone name must be unique.', {
          position: 'top-center'
        });
        return;
      }

      setZoneSceneList((prevList) => {
        const updatedList = [
          ...prevList,
          {
            zone_name: zoneValue.trim(),
            scenes: scenesList.map((item) => ({
              scene_name: item.trim()
            }))
          }
        ];
        return updatedList;
      });

      setZoneValue('');
      setCurrSceneValue('');
      setScenesList([]);
      setZoneEditingIndex(-1);
    };

    const updateEditZone = () => {
      if (editingZoneIndex > -1) {
        setZoneSceneList((prevList) => {
          const isDuplicateZoneName = prevList.some(
            (zone, index) => zone.zone_name === zoneValue && index !== editingZoneIndex
          );
          if (isDuplicateZoneName) {
            toast.error('Zone name must be unique.', {
              position: 'top-center'
            });
            return prevList;
          }

          const uniqueScenes = Array.from(new Set(scenesList.map((item) => item.trim())));

          const updatedList = [...prevList];
          updatedList[editingZoneIndex] = {
            zone_name: zoneValue.trim(),
            scenes: uniqueScenes.map((sceneName) => ({
              scene_name: sceneName
            }))
          };

          return updatedList;
        });

        setZoneValue('');
        setCurrSceneValue('');
        setScenesList([]);
        setZoneEditingIndex(-1);
        setData((prevData) => ({
          ...prevData,
          zones: []
        }));
      }
    };

    const onClickEditZone = (index: number) => {
      setZoneEditingIndex(index);
      setZoneValue(zoneSceneList[index].zone_name);

      if (zoneSceneList[index].scenes.length) {
        setScenesList(
          Array.from(
            new Set(
              zoneSceneList[index].scenes
                .map((item) => item.scene_name?.trim())
                .filter((sceneName): sceneName is string => sceneName !== undefined)
            )
          )
        );
      }
    };

    const onClickDeleteZone = (index: number) => {
      setZoneSceneList((prevList) => {
        const updatedList = prevList.filter((_, i) => i !== index);
        return updatedList;
      });
      setData((prevData) => ({
        ...prevData,
        zones: []
      }));
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <SearchableDropdown
              label="Customer"
              inputName="customer"
              options={allCustomers}
              value={data.customer?.customer_id ?? ''}
              onChange={handleChange}
              validator={validator}
              required
            />
            <Tooltip title="Create Customer">
              <img
                onClick={() => {
                  setOpen(false);
                  setCreateCustomereDrawer(true);
                }}
                src={CreateIcon}
                className="img-fluid link-icon"
                alt="Create Icon"
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SearchableDropdown
            label={'Estimator'}
            inputName="estimator"
            options={allEstimators}
            value={data.estimator?.user_id ?? ''}
            onChange={handleChange}
            validator={validator}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextfield
            labelText="Job Name"
            name="job_name"
            placeholder="Job Name"
            value={data.job_name}
            onChange={handleChange}
            maxLength={25}
            validator={validator}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextfield
            labelText="Pricing Due"
            name={`pricing_due_date`}
            value={data.pricing_due_date}
            onChange={handleChange}
            type="date"
            size="medium"
            validator={validator}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextfield
            labelText="Anticipated Start Date"
            name={`anticipated_start_date`}
            value={data?.anticipated_start_date ?? ''}
            onChange={handleChange}
            type="date"
            size="medium"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextfield
            labelText="Anticipated Ship Date"
            name={`anticipated_ship_date`}
            value={data?.anticipated_ship_date ?? ''}
            onChange={handleChange}
            type="date"
            size="medium"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SearchableDropdown
            label={'Bid Type'}
            inputName="bid_type"
            options={bidTypes}
            value={data.bid_type ?? ''}
            onChange={handleChange}
            validator={validator}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SearchableDropdown
            label={'Deal Source'}
            inputName="deal_source"
            options={dealSources}
            value={data.deal_source ?? ''}
            onChange={handleChange}
            validator={validator}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SearchableDropdown
            label={'Job Type'}
            inputName="job_type"
            options={jobTypes}
            value={data.job_type ?? ''}
            onChange={handleChange}
            validator={validator}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextfield
            labelText="Client Budget"
            name={`job_value`}
            value={data.job_value}
            onChange={handleChange}
            onKeyPress={handlekeyDown}
            type="number"
            size="medium"
            validator={validator}
            prefix={<AttachMoneyOutlinedIcon sx={{ fontSize: '16px' }} />}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SearchableDropdown
            label={'Deal Owner'}
            inputName="deal_owner"
            options={allDealOwners}
            value={data.deal_owner ?? ''}
            onChange={handleChange}
            validator={validator}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} container alignItems="center">
          {imageData?.image ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {imageData.image.type === 'application/pdf' ? (
                <iframe
                  src={URL.createObjectURL(imageData.image)}
                  style={{
                    width: '150px',
                    height: '200px',
                    paddingTop: '12px'
                  }}
                  title="PDF Preview"
                />
              ) : (
                <img
                  alt="Preview"
                  src={URL.createObjectURL(imageData.image)}
                  style={{
                    width: '70px',
                    height: '70px'
                  }}
                />
              )}

              <span style={{ textAlign: 'center', fontSize: '14px', color: '#555' }}>
                {imageData.imageName || 'No File Selected'}
              </span>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <img
                src={AttachmentIcon}
                alt="Item"
                style={{
                  width: '30px',
                  height: '30px',
                  marginRight: '10px'
                }}
              />
              <span style={{ fontSize: '14px', color: '#555' }}>No File Selected</span>
            </div>
          )}

          <div
            style={{
              marginLeft: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end'
            }}>
            <input
              style={{ display: 'none' }}
              type="file"
              name="image"
              id="file-input"
              multiple
              onChange={(event) => {
                setImageData({
                  image: event.target.files ? event.target.files[0] : null,
                  imageName: event.target.files ? event.target.files[0].name : 'No File Selected'
                });
              }}
              accept="image/png, image/jpeg, image/jpg, .pdf"
            />
            <CustomButton
              label="Choose file"
              onClick={() => document.getElementById('file-input')?.click()}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <CustomTextfield
            labelText="Description"
            name={`description`}
            value={data.description}
            onChange={handleChange}
            type="date"
            placeholder="This is a description for an Estimate Item"
            size="medium"
            validator={validator}
            maxLength={descriptionMaxLength}
            isRequired
            isTextarea
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            component="fieldset"
            sx={{
              border: '1px solid #ccc',
              borderRadius: '5px',
              position: 'relative',
              padding: 3,
              paddingTop: 4,
              zIndex: 0,
              minHeight: '250px',
              backgroundColor: 'white'
            }}>
            <Typography
              component="span"
              sx={{
                fontWeight: 'bold',
                position: 'absolute',
                top: '-10px',
                left: '10px',
                backgroundColor: 'white',
                zIndex: 22
              }}>
              Client Contact Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CustomTextfield
                  labelText="Client Contact Name"
                  name={`client_contact_name`}
                  value={data.client_contact_name}
                  placeholder="Enter Contact Name"
                  onChange={handleChange}
                  type="text"
                  size="medium"
                  maxLength={nameMaxLength}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneNumberInput
                  label="Client Contact Phone"
                  name={`client_contact_phone`}
                  value={data.client_contact_phone ?? ''}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextfield
                  labelText="Client Contact Email"
                  name={`client_contact_email`}
                  value={data.client_contact_email}
                  placeholder="Enter Contact Email"
                  onChange={handleChange}
                  type="text"
                  size="medium"
                  validator={validator}
                  maxLength={50}
                  isRequired
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            component="fieldset"
            sx={{
              border: '1px solid #ccc',
              borderRadius: '5px',
              position: 'relative',
              padding: 3,
              paddingTop: 4,
              zIndex: 0,
              minHeight: '300px',
              backgroundColor: 'white'
            }}>
            <Typography
              component="span"
              sx={{
                fontWeight: 'bold',
                position: 'absolute',
                top: '-10px',
                left: '10px',
                backgroundColor: 'white',
                zIndex: 22
              }}>
              Project Structure
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CustomTextfield
                  labelText="Zone"
                  name={`zone`}
                  value={zoneValue}
                  onChange={handleChange}
                  type="text"
                  size="medium"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'center',
                    gap: '8px',
                    width: '100%',
                    flexWrap: 'wrap',
                    border: '2px solid lightgray',
                    borderRadius: '4px',
                    flexDirection: 'column'
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      gap: '5px'
                    }}>
                    {scenesList.map((item, index) =>
                      editingSceneIndex === index ? (
                        <Input
                          key={index}
                          value={editingSceneValue}
                          onChange={handleEditChange}
                          onKeyDown={handleEditKeyDown}
                          onBlur={handleEditBlur}
                          autoFocus
                          sx={{ padding: '6px', marginLeft: '10px', width: '6rem' }}
                        />
                      ) : (
                        <Chip
                          size="medium"
                          onDelete={() => handleDelete(item, index)}
                          label={item}
                          key={index}
                          onClick={() => handleChipEditStart(index)}
                          sx={{ marginTop: '4px', marginLeft: '4px' }}
                        />
                      )
                    )}
                  </Box>
                  <Input
                    value={currSceneValue}
                    onChange={(e) => setCurrSceneValue(e.target.value)}
                    onKeyDown={handleKeyUp}
                    placeholder="Press Enter to add Scene"
                    sx={{ padding: '6px', marginLeft: '10px' }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'end', mb: '25px' }}>
                <CustomButton
                  label={editingZoneIndex > -1 ? 'Update' : 'Add'}
                  disabled={!zoneValue}
                  onClick={() => (editingZoneIndex > -1 ? updateEditZone() : addZone())}
                  sx={{ padding: '5px', marginLeft: '8px' }}
                />
              </Grid>
              {zoneSceneList.map((zone, index) => (
                <Grid item xs={12} lg={6} key={index}>
                  <Box sx={{ marginY: '10px' }}>
                    <ZoneHierarchy
                      index={index}
                      zoneName={zone.zone_name}
                      scenes={zone.scenes
                        .map((scene) => scene.scene_name)
                        .filter((name): name is string => name !== undefined)}
                      onEdit={(index) => onClickEditZone(index)}
                      onDelete={(index) => onClickDeleteZone(index)}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );
  }
);
InitiateEstimateForm.displayName = 'InitiateEstimateForm';
