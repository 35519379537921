import type { StateCreator } from 'zustand';
import type { User, Vendor, Project, CreditCard, Customer, Vehicle } from 'api/types';

export interface AdminWorkspaceSlice {
  allUsers: User[];
  allVendors: Vendor[];
  allJobs: Project[];
  allVehicles: Vehicle[];
  allCreditCards: CreditCard[];
  allCustomers: Customer[];
  selectedUser?: User;
  selectedVendor?: Vendor;
  selectedJob?: Project;
  selectedCreditCard?: CreditCard;
  selectedCustomer?: Customer;
  selectedVehicle?: Vehicle;
  qbAlerts?: [];
  setAllCustomers: (customers: Customer[]) => void;
  setAllUser: (users: User[]) => void;
  updateUser: (user: User) => void;
  setAllVendors: (vendors: Vendor[]) => void;
  setAllVehicles: (users: Vehicle[]) => void;
  setAllCreditCards: (users: CreditCard[]) => void;
  updateVendor: (vendor: Vendor) => void;
  setAllJobs: (jobs: Project[]) => void;
  updateJob: (job: Project) => void;
  updateVehicle: (vehicle: Vehicle) => void;
  updateCreditCard: (creditCard: CreditCard) => void;
  setSelectedUser: (user: User | undefined) => void;
  setSelectedVendor: (vendor: Vendor | undefined) => void;
  setSelectedJob: (job: Project | undefined) => void;
  setSelectedCreditCard: (creditCard: CreditCard | undefined) => void;
  setSelectedCustomer: (customer: Customer | undefined) => void;
  setSelectedVehicle: (vehicle: Vehicle | undefined) => void;
  setQBAlerts: (alerts: []) => void;
}

export const adminWorkspaceSlice: StateCreator<AdminWorkspaceSlice> = (set) => ({
  allUsers: [],
  qbAlerts: [],
  allVendors: [],
  allJobs: [],
  allVehicles: [],
  allCreditCards: [],
  allCustomers: [],
  setAllCustomers: (allCustomers: Customer[]) =>
    set(() => ({
      allCustomers
    })),
  setAllCreditCards: (allCreditCards: CreditCard[]) =>
    set(() => ({
      allCreditCards
    })),
  setAllUser: (allUsers: User[]) =>
    set(() => ({
      allUsers
    })),
  updateUser: (user: User) =>
    set((state) => ({
      allUsers: state.allUsers.map((u) => (u.user_id === user.user_id ? user : u))
    })),
  updateCreditCard: (creditCard: CreditCard) =>
    set((state) => ({
      allCreditCards: state.allCreditCards.map((cc) =>
        cc.credit_card_id === creditCard.credit_card_id ? creditCard : cc
      )
    })),
  updateVehicle: (vehicle) =>
    set((state) => ({
      allVehicles: state.allVehicles.map((v) =>
        v.vehicle_type_id === vehicle.vehicle_type_id ? vehicle : v
      )
    })),
  setAllVendors: (allVendors: Vendor[]) =>
    set(() => ({
      allVendors
    })),
  setAllVehicles: (allVehicles: Vehicle[]) =>
    set(() => ({
      allVehicles
    })),
  updateVendor: (vendor: Vendor) =>
    set((state) => ({
      allVendors: state.allVendors.map((v) => (v.vendor_id === vendor.vendor_id ? vendor : v))
    })),
  setAllJobs: (allJobs: Project[]) =>
    set(() => ({
      allJobs
    })),
  updateJob: (job: Project) =>
    set((state) => ({
      allJobs: state.allJobs.map((j) => (j.project_id === job.project_id ? job : j))
    })),
  setSelectedUser: (selectedUser: User | undefined) =>
    set(() => ({
      selectedUser
    })),
  setSelectedVendor: (selectedVendor: Vendor | undefined) =>
    set(() => ({
      selectedVendor
    })),
  setSelectedCreditCard: (selectedCreditCard: CreditCard | undefined) =>
    set(() => ({
      selectedCreditCard
    })),
  setSelectedJob: (selectedJob: Project | undefined) =>
    set(() => ({
      selectedJob
    })),
  setSelectedCustomer: (selectedCustomer: Customer | undefined) =>
    set(() => ({
      selectedCustomer
    })),
  setSelectedVehicle: (selectedVehicle: Vehicle | undefined) =>
    set(() => ({
      selectedVehicle
    })),
  setQBAlerts: (qbAlerts) =>
    set(() => ({
      qbAlerts
    }))
});
