import { Box, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { updatePurchaseOrderItems } from 'api/index';
import type {
  ApiInProgressType,
  GetPurchaseOrders,
  PoModalModifiers,
  PurchaseOrderItem
} from 'api/types';
import BridgeWaterLogo from 'assets/images/bridgewater-logo.svg';
import DeleteIcon from 'assets/images/delete-icon.svg';
import LocationIcon from 'assets/images/distance.svg';
import EditableTableCell from 'components/EditableTableCell';
import type { DropdownOptionType } from 'components/inputs/Dropdown';
import DropDown from 'components/NewLayout/Dropdown';
import DataTable from 'components/NewLayout/Table';
import CustomTextfield from 'components/NewLayout/Textfield';
import { useState } from 'react';
import toast from 'react-hot-toast';
import {
  calculateItemsTotal,
  calculateModifiersTotal,
  dateFormatter,
  roundToTwoDecimalPlaces
} from 'utils';
import {
  descriptionMaxLength,
  nameMaxLength,
  purchaseOrderPaymentTerms,
  unitOfMeasures
} from 'utils/constants';
import type { DataTableObjectType } from 'utils/types';

const safeParseFloat = (value: string | undefined | null): number => {
  if (!value) return 0;
  const parsed = parseFloat(value);
  return isNaN(parsed) ? 0 : parsed;
};

export default function EditPoData({
  purchaseOrder,
  newRows,
  updateSelectedPurchaseOrder,
  deliveryAddress,
  specialInstructions,
  setSpecialInstructions,
  poModifierDetails,
  allProjects,
  newRowButton,
  deleteRow,
  onEditingCompleteforNewRow,
  parentApiInProgress,
  setParentApiInProgress,
  handleChange,
  selectedPaymentTerms
}: {
  purchaseOrder: GetPurchaseOrders;
  newRows: Partial<PurchaseOrderItem>[];
  updateSelectedPurchaseOrder: (purchaseOrder: GetPurchaseOrders) => void;
  deliveryAddress: string;
  specialInstructions: string;
  setSpecialInstructions: (res: string) => void;
  poModifierDetails: PoModalModifiers;
  allProjects: DropdownOptionType[];
  newRowButton: () => JSX.Element;
  deleteRow: (row: PurchaseOrderItem) => void;
  onEditingCompleteforNewRow: (
    dataToUpdate: Partial<PurchaseOrderItem>,
    tempRowId: string,
    cellId: string
  ) => void;
  parentApiInProgress: ApiInProgressType;
  setParentApiInProgress: React.Dispatch<React.SetStateAction<ApiInProgressType>>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedPaymentTerms: string;
}) {
  const [apiInProgress, setApiInProgress] = useState<ApiInProgressType>({});

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const existingPurchaseOrderItemsTotal = () =>
    purchaseOrder.purchase_order_items
      ? calculateItemsTotal(purchaseOrder.purchase_order_items)
      : 0.0;

  const newPurchaseOrderItemsTotal = () => calculateItemsTotal(newRows);

  const modifiersTotal = () => calculateModifiersTotal(poModifierDetails);

  const finalTotal = () =>
    Number(modifiersTotal()) +
    Number(existingPurchaseOrderItemsTotal()) +
    Number(newPurchaseOrderItemsTotal());

  const onEditingComplete = async (
    dataToUpdate: Partial<PurchaseOrderItem>,
    purchaseOrderItemId: string,
    cellId: string
  ): Promise<void> => {
    const updatedItems =
      purchaseOrder.purchase_order_items &&
      purchaseOrder.purchase_order_items.map((item) =>
        item.purchase_order_item_id === purchaseOrderItemId ? { ...item, ...dataToUpdate } : item
      );
    const updatedPurchaseOrder = {
      ...purchaseOrder,
      purchase_order_items: updatedItems
    };

    setApiInProgress((prev) => ({ ...prev, [cellId]: true }));
    updateSelectedPurchaseOrder(updatedPurchaseOrder);
    setApiInProgress((prev) => ({ ...prev, [cellId]: false }));
  };

  const POColumns: DataTableObjectType<PurchaseOrderItem>[] = [
    {
      name: 'Action',
      prop: 'action',
      data: (row) => (
        <Box>
          <IconButton color="error" onClick={() => deleteRow(row)}>
            <img src={DeleteIcon} />
          </IconButton>
        </Box>
      )
    },
    {
      name: 'Job Name',
      prop: 'project_name',
      data: (row: PurchaseOrderItem) => {
        const cellId = `${row.purchase_order_item_id}_project_name`;
        const isNewRow = String(row.purchase_order_item_id).startsWith('temp');

        return (
          <EditableTableCell
            name="project_name"
            value={row.project_name || ''}
            onEditComplete={(val) => {
              const selectedProject = allProjects.find((v) => v.id === Number(val));
              const updatedData = {
                project_id: Number(val),
                project_name: selectedProject?.value
              };

              if (isNewRow) {
                onEditingCompleteforNewRow(updatedData, row.purchase_order_item_id ?? '', cellId);
              } else {
                onEditingComplete(updatedData, row.purchase_order_item_id ?? '', cellId);
              }
            }}
            isDropdown
            options={allProjects}
            apiInProgress={isNewRow ? parentApiInProgress[cellId] : apiInProgress[cellId]}
            setApiInProgress={(val) => {
              if (isNewRow) {
                setParentApiInProgress((prev) => ({
                  ...prev,
                  [cellId]: val
                }));
              } else {
                setApiInProgress((prev) => ({
                  ...prev,
                  [cellId]: val
                }));
              }
            }}
            key={cellId}
          />
        );
      }
    },
    {
      name: 'Item',
      prop: 'item_name',
      data: (row: PurchaseOrderItem) => {
        const cellId = `${row.purchase_order_item_id}_item_name`;
        const isNewRow = String(row.purchase_order_item_id).startsWith('temp');

        return (
          <EditableTableCell
            value={row.item_name || ''}
            maxLength={nameMaxLength}
            onEditComplete={(val) => {
              const updatedData = { item_name: val };

              if (isNewRow) {
                onEditingCompleteforNewRow?.(updatedData, row.purchase_order_item_id ?? '', cellId);
              } else {
                onEditingComplete(updatedData, row.purchase_order_item_id ?? '', cellId);
              }
            }}
            key={cellId}
            apiInProgress={isNewRow ? parentApiInProgress[cellId] : apiInProgress[cellId]}
            setApiInProgress={(val) => {
              if (isNewRow) {
                setParentApiInProgress((prev) => ({
                  ...prev,
                  [cellId]: val
                }));
              } else {
                setApiInProgress((prev) => ({
                  ...prev,
                  [cellId]: val
                }));
              }
            }}
          />
        );
      }
    },
    {
      name: 'Description',
      prop: 'description',
      data: (row: PurchaseOrderItem) => {
        const cellId = `${row.purchase_order_item_id}_description`;
        const isNewRow = String(row.purchase_order_item_id).startsWith('temp');

        return (
          <EditableTableCell
            value={row.description || ''}
            maxLength={descriptionMaxLength}
            onEditComplete={(val) => {
              const updatedData = {
                description: val
              };

              if (isNewRow) {
                onEditingCompleteforNewRow(updatedData, row.purchase_order_item_id ?? '', cellId);
              } else {
                onEditingComplete(updatedData, row.purchase_order_item_id ?? '', cellId);
              }
            }}
            key={cellId}
            apiInProgress={isNewRow ? parentApiInProgress[cellId] : apiInProgress[cellId]}
            setApiInProgress={(val) => {
              if (isNewRow) {
                setParentApiInProgress((prev) => ({
                  ...prev,
                  [cellId]: val
                }));
              } else {
                setApiInProgress((prev) => ({
                  ...prev,
                  [cellId]: val
                }));
              }
            }}
          />
        );
      }
    },
    {
      name: 'Quantity',
      prop: 'quantity',
      data: (row: PurchaseOrderItem) => {
        const cellId = `${row.purchase_order_item_id}_quantity`;
        const isNewRow = String(row.purchase_order_item_id).startsWith('temp');
        return (
          <EditableTableCell
            value={row.quantity || ''}
            name="quantity"
            onEditComplete={(val) => {
              const updatedData = {
                quantity: val
              };

              if (isNewRow) {
                onEditingCompleteforNewRow(updatedData, row.purchase_order_item_id ?? '', cellId);
              } else {
                onEditingComplete(updatedData, row.purchase_order_item_id ?? '', cellId);
              }
            }}
            key={cellId}
            selectText
            type="number"
            apiInProgress={isNewRow ? parentApiInProgress[cellId] : apiInProgress[cellId]}
            setApiInProgress={(val) => {
              if (isNewRow) {
                setParentApiInProgress((prev) => ({
                  ...prev,
                  [cellId]: val
                }));
              } else {
                setApiInProgress((prev) => ({
                  ...prev,
                  [cellId]: val
                }));
              }
            }}
          />
        );
      }
    },
    {
      name: 'UoM',
      prop: 'unit_of_measure',
      data: (row: PurchaseOrderItem) => {
        const cellId = `${row.purchase_order_item_id}_unit_of_measure`;
        const isNewRow = String(row.purchase_order_item_id).startsWith('temp');

        return (
          <EditableTableCell
            value={row.unit_of_measure || ''}
            onEditComplete={(val) => {
              const updatedData = {
                unit_of_measure: val
              };

              if (isNewRow) {
                onEditingCompleteforNewRow(updatedData, row.purchase_order_item_id ?? '', cellId);
              } else {
                onEditingComplete(updatedData, row.purchase_order_item_id ?? '', cellId);
              }
            }}
            key={cellId}
            isDropdown
            options={unitOfMeasures}
            apiInProgress={isNewRow ? parentApiInProgress[cellId] : apiInProgress[cellId]}
            setApiInProgress={(val) => {
              if (isNewRow) {
                setParentApiInProgress((prev) => ({
                  ...prev,
                  [cellId]: val
                }));
              } else {
                setApiInProgress((prev) => ({
                  ...prev,
                  [cellId]: val
                }));
              }
            }}
          />
        );
      }
    },
    {
      name: 'Unit Price',
      prop: 'price',
      data: (row: PurchaseOrderItem) => {
        const cellId = `${row.purchase_order_item_id}_price`;
        const isNewRow = String(row.purchase_order_item_id).startsWith('temp');

        return (
          <EditableTableCell
            value={`$${row.price}`}
            onEditComplete={(val) => {
              const updatedData = {
                price: val
              };
              if (isNewRow) {
                onEditingCompleteforNewRow(updatedData, row.purchase_order_item_id ?? '', cellId);
              } else {
                onEditingComplete(updatedData, row.purchase_order_item_id ?? '', cellId);
              }
            }}
            name="price"
            type="number"
            selectText
            key={cellId}
            apiInProgress={isNewRow ? parentApiInProgress[cellId] : apiInProgress[cellId]}
            setApiInProgress={(val) => {
              if (isNewRow) {
                setParentApiInProgress((prev) => ({
                  ...prev,
                  [cellId]: val
                }));
              } else {
                setApiInProgress((prev) => ({
                  ...prev,
                  [cellId]: val
                }));
              }
            }}
          />
        );
      }
    },
    {
      name: 'Total',
      prop: 'total',
      data: (row: PurchaseOrderItem) => (
        <>
          $
          {roundToTwoDecimalPlaces(
            safeParseFloat(row.price) * safeParseFloat(row.quantity?.toString())
          )}
        </>
      )
    }
  ];

  const vendorAddress = () =>
    [
      purchaseOrder.vendor?.billed_from_address1,
      purchaseOrder.vendor?.billed_from_city,
      purchaseOrder.vendor?.billed_from_state,
      purchaseOrder.vendor?.billed_from_postal_code,
      purchaseOrder.vendor?.billed_from_country + '.'
    ]
      .filter(Boolean)
      .join(', ');

  return (
    purchaseOrder.purchase_order_items && (
      <Grid
        container
        spacing={2}
        minWidth="auto"
        id="po-card-data"
        justifyContent={'space-between'}>
        <Grid item xs={6}>
          <img src={BridgeWaterLogo} width={isMobile ? '150px' : '200px'} alt="Bridgewater Logo" />
          <Typography component="div" sx={{ fontSize: '20px', color: '#4E4E4E' }}>
            Bridgewater Studio Inc.
          </Typography>
        </Grid>

        <Grid item xs={6} textAlign="center">
          <Typography
            component="div"
            sx={{ fontSize: isMobile ? '18px' : '32px', fontWeight: '700' }}>
            Purchase Order
          </Typography>
          <Typography component="div">
            <img
              src={LocationIcon}
              style={{ marginRight: '10px', verticalAlign: 'middle' }}
              alt="Location"
            />
            4834 S Oakley Ave, Chicago, IL 60609 | (312) 702-1335
          </Typography>
          <Typography component="div" sx={{ mb: 3 }}>
            <Typography component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography sx={{ fontWeight: 'bold', width: '150px', textAlign: 'left' }}>
                PO Number:
              </Typography>
              <Typography sx={{ width: '100px', textAlign: 'left' }}>
                {purchaseOrder.purchase_order_number}
              </Typography>
            </Typography>
            <Typography component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography sx={{ fontWeight: 'bold', width: '150px', textAlign: 'left' }}>
                PO Date:
              </Typography>
              <Typography sx={{ width: '100px', textAlign: 'left' }}>
                {dateFormatter(purchaseOrder.created_at ?? '', 'date')}
              </Typography>
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            component="div"
            sx={{
              display: 'flex',
              mb: 1,
              justifyContent: 'space-around'
            }}>
            <Typography
              component="div"
              sx={{
                textAlign: 'left',
                p: 3,
                width: isMobile ? '100%' : '360px',
                background: '#f4f4f4',
                borderRadius: '20px'
              }}>
              <Typography component="div" sx={{ fontSize: '18px', fontWeight: '600', mb: 1 }}>
                Vendor Details
              </Typography>
              <Typography sx={{ mb: 1 }}>
                <Typography component="span" sx={{ fontWeight: 'bold', mr: 1 }}>
                  Company Name:
                </Typography>
                {purchaseOrder.vendor?.vendor_name}
              </Typography>
              <Typography sx={{ mb: 1 }}>
                <Typography component="span" sx={{ fontWeight: 'bold', mr: 1 }}>
                  Billing Address:
                </Typography>
                {vendorAddress()}
              </Typography>
              <Typography sx={{ mb: 1 }}>
                <Typography component="span" sx={{ fontWeight: 'bold', mr: 1 }}>
                  Phone:
                </Typography>
                {purchaseOrder.vendor?.phone_number}
              </Typography>
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              mb: 1,
              justifyContent: 'space-around'
            }}>
            <Typography
              component="div"
              sx={{
                textAlign: 'left',
                p: 3,
                width: isMobile ? '100%' : '360px',
                background: '#f4f4f4',
                borderRadius: '20px'
              }}>
              <Typography component="div" sx={{ fontSize: '18px', fontWeight: '600', mb: 1 }}>
                Delivery Details
              </Typography>
              <Typography sx={{ mb: 1 }}>
                <img
                  src={LocationIcon}
                  width="15px"
                  style={{ marginRight: '10px' }}
                  alt="Location"
                />
                {deliveryAddress}
              </Typography>
              <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                <Typography component="span" sx={{ fontWeight: 'bold', mr: 1 }}>
                  Payment Terms:
                </Typography>
                <Box sx={{ width: '60%' }}>
                  <DropDown
                    label=""
                    options={purchaseOrderPaymentTerms}
                    value={selectedPaymentTerms}
                    inputName="payment_terms"
                    size="small"
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <Typography sx={{ mb: 1 }}>
                <Typography component="span" sx={{ fontWeight: 'bold', mr: 1 }}>
                  Requested By:
                </Typography>
                {localStorage.getItem('userName')}
              </Typography>
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <DataTable
            items={[...purchaseOrder.purchase_order_items, ...newRows]}
            columns={POColumns}
            loading={false}
            hidePagination
            rowsPerPage={
              newRows.length
                ? purchaseOrder.purchase_order_items.length + newRows.length
                : newRows.length
            }
            tableHeight="40vh"
          />
          <p />
          {newRowButton()}
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ mt: 3 }}>
              <Box
                sx={{
                  borderRadius: '20px',
                  width: '75%',
                  marginLeft: 'auto',
                  marginRight: '10%',
                  padding: 2
                }}>
                <Typography sx={{ fontSize: '18px', fontWeight: '500', mb: 1 }}>
                  Special Instructions
                </Typography>
                <CustomTextfield
                  labelText=""
                  name="special-instruction"
                  isTextarea
                  value={specialInstructions}
                  onChange={(e) => setSpecialInstructions(e.target.value)}
                />
                <Typography sx={{ fontSize: '18px', fontWeight: '500', mt: 2, mb: 1 }}>
                  Authorized
                </Typography>
                <CustomTextfield labelText="" name="authorized" disabled />
              </Box>
            </Grid>

            {/* Totals Section */}
            <Grid item xs={6} sx={{ position: 'relative' }}>
              <Typography
                component="div"
                sx={{
                  p: 3,
                  background: '#f4f4f4',
                  borderRadius: '20px',
                  position: 'absolute',
                  width: '75%',
                  top: '25%',
                  right: '15%',
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr'
                }}>
                <Typography component="div" sx={{ fontWeight: 'bold' }}>
                  <Typography>Total Net:</Typography>
                  <Typography>Shipping:</Typography>
                  <Typography>Taxes:</Typography>
                  <Typography>Discount:</Typography>
                  <Typography>Deposit:</Typography>
                  <Typography>Refund:</Typography>
                  <Typography sx={{ mt: 2, fontSize: '24px' }}>TOTAL:</Typography>
                </Typography>
                <Typography component="div" sx={{ textAlign: 'right' }}>
                  <Typography>
                    $
                    {roundToTwoDecimalPlaces(
                      existingPurchaseOrderItemsTotal() + newPurchaseOrderItemsTotal()
                    )}
                  </Typography>
                  <Typography>
                    ${roundToTwoDecimalPlaces(poModifierDetails?.shipping_cost || '0')}
                  </Typography>
                  <Typography>
                    ${roundToTwoDecimalPlaces(poModifierDetails?.estimated_taxes || '0')}
                  </Typography>
                  <Typography>
                    ${roundToTwoDecimalPlaces(poModifierDetails?.discount || '0')}
                  </Typography>
                  <Typography>
                    ${roundToTwoDecimalPlaces(poModifierDetails?.deposit || '0')}
                  </Typography>
                  <Typography>
                    ${roundToTwoDecimalPlaces(poModifierDetails?.refund || '0')}
                  </Typography>
                  <Typography sx={{ mt: 2, fontSize: '24px' }}>
                    ${roundToTwoDecimalPlaces(finalTotal())}
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  );
}
