import {
  Box,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import type { PaymentPoDetails, PaymentScheduleForm, PoModalModifiers, Vendor } from 'api/types';
import AddCirlce from 'assets/images/add_circle.svg';
import CheckCirleSuccess from 'assets/images/check_circle_success.svg';
import DeleteIcon from 'assets/images/delete-icon.svg';
import type { DropdownOptionType } from 'components/inputs/Dropdown';
import CustomButton from 'components/NewLayout/Button';
import CustomDialog from 'components/NewLayout/Dialog';
import DropDown from 'components/NewLayout/Dropdown';
import CustomTextfield from 'components/NewLayout/Textfield';
import { useMemo, useReducer, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Validator from 'simple-react-validator';
import { usePaymentWorkspaceStore } from 'store/index';
import { getValidations, MAX_11_DIGITS_TWO_DECIMALS_REGEX } from 'utils/index';

export const StyledLegend = styled('div')(({ theme }) => ({
  fontSize: '16px',
  marginBottom: theme.spacing(1),
  width: 'max-content',
  background: '#fff',
  marginTop: '-29px !important',
  paddingLeft: '7px',
  paddingRight: '5px'
}));

export const paymentMethods: DropdownOptionType[] = [
  { id: 'ACH', value: 'ACH' },
  { id: 'Wire', value: 'Wire' },
  { id: 'Live Check', value: 'Live Check' },
  { id: 'Bill Pay', value: 'Bill Pay' },
  { id: 'Credit Card', value: 'Credit Card' }
];

export default function PaymentScheduleModal({
  poModifiers,
  vendorBillingData,
  purchaseOrderId,
  selectedPoTotalPrice,
  // selectedPoOtherBillTotal,
  selectedVendorPayment,
  paymentPoDetails,
  onClose,
  onComplete,
  createPurchaseOrderAndPaymentSchedule,
  onConfirmWarning,
  setPoModalData
}: {
  poModifiers?: PoModalModifiers;
  vendorBillingData?: Vendor[];
  purchaseOrderId?: string;
  selectedPoTotalPrice: number;
  // selectedPoOtherBillTotal?: string;
  selectedVendorPayment?: string;
  paymentPoDetails?: PaymentPoDetails;
  onClose: () => void;
  onComplete?: () => void;
  createPurchaseOrderAndPaymentSchedule?: (data: PaymentScheduleForm[]) => Promise<void>;
  onConfirmWarning?: (val: boolean) => Promise<void>;
  setPoModalData?: React.Dispatch<React.SetStateAction<PoModalModifiers>>;
}) {
  const initialPaymentScheduleObj = {
    index: 0,
    po_in_total: '',
    amount: undefined,
    due_date: '',
    payment_method: selectedVendorPayment
  };
  const [modalShow, setShowModal] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [data, setData] = useState<PaymentScheduleForm[]>([initialPaymentScheduleObj]);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [delayPaymentScheduleModal, setDelayPaymentScheduleModal] = useState<boolean>(false);
  const validator = useRef(new Validator(getValidations()));
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const theme = useTheme();

  // let total = 0;
  // POModalData?.forEach((d) => {
  //   const price = safeParseFloat(d?.price);
  //   const quantity = safeParseFloat(d?.quantity);
  //   total += price * quantity;
  // });

  const { poModifiersTotal, totalIncludingPOModifiersTotal } = useMemo(() => {
    const modifiersTotal =
      Number(poModifiers?.estimated_taxes) +
      Number(poModifiers?.shipping_cost) -
      Number(poModifiers?.discount) -
      Number(poModifiers?.deposit) -
      Number(poModifiers?.refund);

    return {
      poModifiersTotal: modifiersTotal,
      totalIncludingPOModifiersTotal: (modifiersTotal + selectedPoTotalPrice).toFixed(2)
    };
  }, [
    poModifiers?.estimated_taxes,
    poModifiers?.shipping_cost,
    poModifiers?.discount,
    poModifiers?.deposit,
    poModifiers?.refund,
    selectedPoTotalPrice
  ]);

  const handleChangePOModifiers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (MAX_11_DIGITS_TWO_DECIMALS_REGEX.test(value) && setPoModalData) {
      setPoModalData((prevState) => {
        if (prevState[name] === value) return prevState; // Prevent redundant updates
        return { ...prevState, [name]: value };
      });
    }
  };

  const handleChangePaymentSchedule = (event, index, field) => {
    const { name, value } = event.target;

    const getTotalPrice = () => totalIncludingPOModifiersTotal;

    if (field === 'amount' && Number(value) < 1) {
      toast.error('Value cannot be equal or less than 1', {
        position: 'top-center',
        id: 'less_than_one'
      });
      setData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [field]: '',
          po_in_total: ''
        };
        return updatedData;
      });
      return;
    }

    if (field === 'po_in_total') {
      const percentageValue = parseFloat(value);
      if (percentageValue < 0 || percentageValue > 100) {
        toast.error('Percentage must be between 0 and 100', {
          position: 'top-center',
          id: 'invalid_percentage'
        });
        return;
      }

      setData((prevData) => {
        const updatedData = [...prevData];
        const totalPrice = getTotalPrice();

        updatedData[index] = {
          ...updatedData[index],
          po_in_total: value,
          amount: parseFloat(((percentageValue / 100) * Number(totalPrice)).toFixed(2))
        };
        return updatedData;
      });
      return;
    }

    if (field === 'amount') {
      setData((prevData) => {
        const updatedData = [...prevData];
        const totalPrice = getTotalPrice();
        const percentage = ((Number(value) / Number(totalPrice)) * 100).toFixed(2);

        updatedData[index] = {
          ...updatedData[index],
          amount: value,
          po_in_total: percentage
        };
        return updatedData;
      });
      return;
    }

    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        [field]: value
      };
      return updatedData;
    });
  };

  const submitForm = async () => {
    if (!validator.current.allValid()) {
      validator.current.showMessages();
      forceUpdate();
      return;
    }

    setLoader(true);

    if (Number(totalIncludingPOModifiersTotal) <= 0) {
      toast.error('Total PO Amount can not be ZERO or less than ZERO', {
        position: 'top-center',
        id: 'zero_price'
      });
      setLoader(false);
      return;
    }

    const newData = data.map((item: PaymentScheduleForm) => ({
      ...item,
      purchase_order_id: purchaseOrderId,
      status: 'Not Approved for Payment'
    }));

    const totalAmount = newData.reduce((sum, item) => sum + (Number(item.amount) || 0), 0);
    const maxAllowedAmount = Number(totalIncludingPOModifiersTotal);

    if (totalAmount < maxAllowedAmount || totalAmount > maxAllowedAmount) {
      toast.error(`Total must match ${maxAllowedAmount}`);
      setLoader(false);
      return;
    }

    try {
      await createPurchaseOrderAndPaymentSchedule?.(newData);
      setShowModal(false);
      setSuccessModal(true);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoader(false);
    }
  };

  const handleAddPaymentSchedule = () => {
    const newIndex = data?.length;
    const newPaymentObj = { ...initialPaymentScheduleObj, index: newIndex };
    setData([...data, newPaymentObj]);
  };

  const handleDeletePaymentSchedule = (indexToDelete: number) => {
    setData((prevData) => prevData?.filter((item) => item.index !== indexToDelete));
  };

  const handleDelayPaymentScheduleCreation = () => {
    if (onConfirmWarning) {
      setLoader(true);
      onConfirmWarning(true)
        .then(() => {
          setLoader(false);
          setShowModal(false);
          setDelayPaymentScheduleModal(false);
          setSuccessModal(true);
        })
        .catch((error) => {
          console.error('Error during creation of delayed payment schedule:', error);
          setLoader(false);
        });
    }
  };

  validator.current.purgeFields();

  return (
    <>
      <CustomDialog
        title="Create Payment Schedule"
        open={modalShow}
        maxWidth="lg"
        closeDialog={() => onClose()}
        loading={loader}
        content={
          <>
            <Box className="payment-modal-table">
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Purchase Order Number</TableCell>
                      <TableCell>Shipping</TableCell>
                      <TableCell>Tax</TableCell>
                      <TableCell>Discount</TableCell>
                      <TableCell>Depost</TableCell>
                      <TableCell>Refund</TableCell>
                      <TableCell>Total PO Amount</TableCell>
                      <TableCell>Total Paid</TableCell>
                      <TableCell>Total Remaining</TableCell>
                      <TableCell>Total Scheduled</TableCell>
                      <TableCell>Vendor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      key={'payment-moda-table'}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{paymentPoDetails?.po_number || '00000'}</TableCell>
                      <TableCell>
                        <CustomTextfield
                          name="shipping_cost"
                          placeholder="$0.00"
                          onChange={handleChangePOModifiers}
                          value={poModifiers?.shipping_cost || '0.00'}
                          selectText
                        />
                      </TableCell>
                      <TableCell>
                        <CustomTextfield
                          name="estimated_taxes"
                          placeholder="$0.00"
                          onChange={handleChangePOModifiers}
                          value={poModifiers?.estimated_taxes || '0.00'}
                          selectText
                        />
                      </TableCell>
                      <TableCell>
                        <CustomTextfield
                          name="discount"
                          placeholder="$0.00"
                          onChange={handleChangePOModifiers}
                          value={poModifiers?.discount || '0.00'}
                          selectText
                        />
                      </TableCell>
                      <TableCell>
                        <CustomTextfield
                          name="deposit"
                          placeholder="$0.00"
                          onChange={handleChangePOModifiers}
                          value={poModifiers?.deposit || '0.00'}
                          selectText
                        />
                      </TableCell>
                      <TableCell>
                        <CustomTextfield
                          name="refund"
                          placeholder="$0.00"
                          onChange={handleChangePOModifiers}
                          value={poModifiers?.refund || '0.00'}
                          selectText
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {'$' +
                          (paymentPoDetails?.total_amount !== undefined
                            ? Number(paymentPoDetails.total_amount) + Number(poModifiersTotal)
                            : Number(totalIncludingPOModifiersTotal)
                          ).toFixed(2)}
                      </TableCell>
                      <TableCell>{'$' + (paymentPoDetails?.paid_amount || 0)}</TableCell>
                      <TableCell>
                        {'$' +
                          (paymentPoDetails?.remaining_amount !== undefined
                            ? Number(paymentPoDetails.remaining_amount) + poModifiersTotal
                            : Number(totalIncludingPOModifiersTotal)
                          ).toFixed(2)}
                      </TableCell>
                      <TableCell>{'$' + (paymentPoDetails?.scheduled_amount || 0)}</TableCell>
                      <TableCell>
                        {paymentPoDetails?.vendor_name ||
                          vendorBillingData?.[0]?.vendor_name ||
                          'No Vendor Name'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Typography component="div" sx={{ marginBottom: '30px !important' }}>
              Create payment schedule by adding the following fields.
            </Typography>
            {data?.map((d, i) => (
              <Box
                key={i}
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  border: `1px solid #dadada`,
                  borderRadius: 1,
                  p: 2,
                  mb: 3
                }}>
                <fieldset style={{ borderColor: theme.palette.divider }}>
                  <StyledLegend>
                    Payment Schedule
                    <div
                      onClick={() => handleDeletePaymentSchedule(Number(d.index))}
                      style={{
                        cursor: 'pointer',
                        display: 'inline-block',
                        margin: '0 0 6px 12px'
                      }}>
                      <img
                        className="img-fluid link-icon"
                        src={DeleteIcon}
                        alt="Delete Icon"
                        // style={{
                        //   pointerEvents: d.disabled ? 'none' : 'auto'
                        // }}
                      />
                    </div>
                  </StyledLegend>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <CustomTextfield
                        type="number"
                        min="0"
                        labelText="Amount"
                        name={`data.${i}.amount`}
                        value={d.amount?.toString() ?? ''}
                        onChange={(event) => handleChangePaymentSchedule(event, i, 'amount')}
                        isRequired
                        maxLength={250}
                        validator={validator}
                        size="medium"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomTextfield
                        type="number"
                        labelText="PO Total in %"
                        name={`data.${i}.po_in_total`}
                        value={d.po_in_total}
                        onChange={(event) => handleChangePaymentSchedule(event, i, 'po_in_total')}
                        isRequired
                        size="medium"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomTextfield
                        validator={validator}
                        labelText="Due Date"
                        name={`data.${i}.due_date`}
                        value={d.due_date}
                        isRequired={true}
                        onChange={(event) => handleChangePaymentSchedule(event, i, 'due_date')}
                        type="date"
                        size="medium"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DropDown
                        label="Payment Method"
                        inputName={`data.${i}.payment_method`}
                        optionText="Select payment method"
                        value={d.payment_method || ''}
                        onChange={(event) =>
                          handleChangePaymentSchedule(event, i, 'payment_method')
                        }
                        options={paymentMethods}
                        validator={validator}
                        required
                      />
                    </Grid>
                  </Grid>
                </fieldset>
              </Box>
            ))}
            <Typography
              component="div"
              sx={{ width: '100%', textAlign: 'center', marginTop: '30px !important' }}>
              <CustomButton
                onClick={handleAddPaymentSchedule}
                type="info"
                label={
                  <>
                    <img src={AddCirlce} style={{ marginRight: '10px' }} /> Add another payment
                  </>
                }
              />
            </Typography>
          </>
        }
        actions={
          <>
            <CustomButton onClick={() => onClose()} variant="outlined" label="Discard" />
            <CustomButton
              onClick={() => {
                setDelayPaymentScheduleModal(true);
                setShowModal(false);
              }}
              label="Delay Payment Schedule Creation"
            />
            <CustomButton
              disabled={!data?.length}
              onClick={() => submitForm()}
              label="Create Payment Schedule"
            />
          </>
        }
      />

      <CustomDialog
        title={
          <Typography component="div" sx={{ color: '#3cbe8e' }}>
            <img src={CheckCirleSuccess} />
            Success!
          </Typography>
        }
        open={successModal}
        maxWidth="xs"
        closeDialog={() => setSuccessModal(false)}
        loading={loader}
        content={<Typography sx={{ mb: 2 }}>Payment schedule created successfully!</Typography>}
        actions={
          <CustomButton
            onClick={() => {
              setSuccessModal(false);
              onClose();
              if (onComplete) onComplete();
            }}
            label="OK"
          />
        }
      />

      <CustomDialog
        title={
          <Typography component="div" sx={{ color: '#3cbe8e' }}>
            <img src={CheckCirleSuccess} />
            Delay Payment Schedule Creation
          </Typography>
        }
        open={delayPaymentScheduleModal}
        maxWidth="xs"
        closeDialog={() => {
          setShowModal(true);
          setDelayPaymentScheduleModal(false);
        }}
        loading={loader}
        content={
          <Typography sx={{ mb: 2 }}>
            You have chosen to delay the creation of a payment schedule. A bill for 100% of the
            amount will be created, the due date will be set to 1/1/2099, and the status will be set
            to &apos;Needs Allocation&apos;. You will need to allocate payments at a later time.
          </Typography>
        }
        actions={
          <>
            <CustomButton
              onClick={() => {
                setShowModal(true);
                setDelayPaymentScheduleModal(false);
              }}
              label="Go Back"
            />
            <CustomButton
              onClick={() => {
                handleDelayPaymentScheduleCreation();
              }}
              label="Proceed"
            />
          </>
        }
      />
    </>
  );
}
