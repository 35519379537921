import { Box, Modal } from '@mui/material';
import { estimateItem, estimateItemValue } from 'api/index';
import type {
  EstimateItem,
  EstimateItemType,
  GetEstimateItemRequest,
  SerializedCanvasState
} from 'api/types';
import Loader from 'components/NewLayout/Loader';
import { useCanvasContext } from 'contexts/canvasContext';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCommonStore } from 'store/index';
import { tabTitle } from 'utils/index';
import type { CanvasVisibilityState } from 'utils/types';
import Canvas, { deserializeCanvasState } from './components/Canvas';
import LaborAccordion from './components/LaborAccordion';
import MaterialAccordion from './components/MaterialAccordion';
import QuantityAccordion from './components/QuantityAccordion';
import TitleHeader from './components/TitleHeader';

export const canvasVisibilityInitialObj: CanvasVisibilityState = {
  global: false,
  quantity: false,
  materialsFabrication: false,
  materialsPaint: false,
  labourFabrication: false,
  labourPaint: false,
  labourOther: false
};

export default function EstimateItemWorkspace() {
  const estimateItemState = window.history.state?.data;
  const [, setIsloading] = useState<boolean>(false);
  const [estimateItemData, setEstimateItemData] = useState<EstimateItem[]>();
  const [estimateItemTypes, setEstimateItemTypes] = useState<EstimateItemType[]>();
  const [quantity, setQuantity] = useState<number>(0);
  const [materialTotalAmount, setMaterialTotalAmount] = useState<number>(0);
  const [labourTotalAmount, setLabourTotalAmount] = useState<number>(0);

  const { setLines, setTexts, setImages, setHasStageBeenSet, canvasVisibility, stageRef } =
    useCanvasContext();

  const setComponentHeading = useCommonStore((state) => state.setComponentHeading);
  const navigate = useNavigate();

  tabTitle('Estimate Item Workspace');

  useEffect(() => {
    setComponentHeading('Estimate Item Workspace');
  }, [setComponentHeading]);

  useEffect(() => {
    fetchEstimateItems();
  }, [estimateItemState, navigate]);

  const fetchEstimateItems = async () => {
    const id = estimateItemState?.estimate_item_id;
    if (id) {
      try {
        setIsloading(true);
        const body: GetEstimateItemRequest = {
          http_method: 'GET',
          data: {
            estimateItemId: id
          }
        };
        const estimateItemData = await estimateItem(body);
        setEstimateItemData(estimateItemData);
        setQuantity(estimateItemData[0].quantity);
        const estimateItemTypes = await estimateItemValue({
          http_method: 'GET',
          data: { estimateItemType: true }
        });
        setEstimateItemTypes(estimateItemTypes);
        loadCanvas(estimateItemData[0]?.canvas);
        setHasStageBeenSet(true);
      } catch (err) {
        console.error('Error fetching estimate data:', err);
      } finally {
        setIsloading(false);
      }
    } else {
      navigate('/job-estimation/estimate-workspace');
    }
  };

  const loadCanvas = async (savedState: SerializedCanvasState) => {
    const runtimeState = await deserializeCanvasState(savedState);
    setLines(runtimeState.lines);
    setTexts(runtimeState.texts);
    setImages(runtimeState.images);

    const stage = stageRef.current;
    if (stage) {
      stage.scale(runtimeState.stageConfig.scale);
      stage.position(runtimeState.stageConfig.position);
      stage.batchDraw();
    }
  };

  return estimateItemData && estimateItemTypes ? (
    <Box>
      <TitleHeader
        title={estimateItemState?.estimate_item_name || 'No Item Name'}
        quantity={quantity}
        totalCost={Number(quantity) * (materialTotalAmount + labourTotalAmount)}
      />
      <Box sx={{ marginY: '15px' }}>
        <QuantityAccordion
          estimateItemId={estimateItemState.estimate_item_id}
          data={estimateItemData}
          setQuantity={(val) => setQuantity(Number(val))}
        />
      </Box>
      <Box sx={{ marginY: '15px' }}>
        <MaterialAccordion
          estimateItemId={estimateItemState.estimate_item_id}
          estimateItemTypes={estimateItemTypes}
          setTotalAmount={(val) => setMaterialTotalAmount(val)}
        />
      </Box>
      <Box sx={{ marginY: '15px' }}>
        <LaborAccordion
          estimateItemId={estimateItemState.estimate_item_id}
          estimateItemTypes={estimateItemTypes}
          setTotalAmount={(val) => setLabourTotalAmount(val)}
        />
      </Box>
      {canvasVisibility.global && (
        <Modal
          open={canvasVisibility.global}
          aria-labelledby="canvas-modal"
          aria-describedby="canvas-modal-description">
          <Box sx={modalStyle}>
            <Canvas estimateItemId={estimateItemState.estimate_item_id} />
          </Box>
        </Modal>
      )}
    </Box>
  ) : (
    <Loader />
  );
}

const modalStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  outline: 'none'
};
