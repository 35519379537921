import {
  Box,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import { deletePurchaseOrderBills, postPaymentSchedule } from 'api/index';
import type {
  GetPurchaseOrders,
  PaymentPoDetails,
  PaymentScheduleForm,
  PaymentSchedulesItems,
  POModal,
  PurchaseOrderBills
} from 'api/types';
import AddCirlce from 'assets/images/add_circle.svg';
import CheckCirleSuccess from 'assets/images/check_circle_success.svg';
import DeleteIcon from 'assets/images/delete-icon.svg';
import type { DropdownOptionType } from 'components/inputs/Dropdown';
import CustomButton from 'components/NewLayout/Button';
import CustomDialog from 'components/NewLayout/Dialog';
import DropDown from 'components/NewLayout/Dropdown';
import CustomTextfield from 'components/NewLayout/Textfield';
import { useEffect, useReducer, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Validator from 'simple-react-validator';
import { calculateModifiersTotal, getValidations } from 'utils/index';
import InfoIcon from 'assets/images/info-icon.svg';

export const StyledLegend = styled('div')(({ theme }) => ({
  fontSize: '16px',
  marginBottom: theme.spacing(1),
  width: 'max-content',
  background: '#fff',
  marginTop: '-29px !important',
  paddingLeft: '7px',
  paddingRight: '5px'
}));

export const paymentMethods: DropdownOptionType[] = [
  { id: 'ACH', value: 'ACH' },
  { id: 'Wire', value: 'Wire' },
  { id: 'Live Check', value: 'Live Check' },
  { id: 'Bill Pay', value: 'Bill Pay' },
  { id: 'Credit Card', value: 'Credit Card' }
];

export default function UpdatePaymentScheduleModal({
  paymentPoDetails,
  purchaseOrder,
  // POModalData,
  selectedVendorPayment,
  onClose,
  onComplete
}: {
  paymentPoDetails?: PaymentPoDetails;
  purchaseOrder: GetPurchaseOrders;
  POModalData?: POModal[];
  selectedVendorPayment?: string;
  onClose: () => void;
  onComplete?: () => void;
}) {
  const paymentScheduleObj = {
    index: 0,
    po_in_total: '',
    amount: undefined,
    due_date: '',
    payment_method: selectedVendorPayment,
    disabled: false
  };
  const [modalShow, setShowModal] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [paymentSchedule, setPaymentSchedule] = useState<PaymentScheduleForm[]>([]);
  const [deleteBillsList, setDeleteBillsList] = useState<number[]>([]);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [isWarningModalVisible, setWarningModalVisibility] = useState<boolean>(false);
  const validator = useRef(new Validator(getValidations()));
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const theme = useTheme();

  useEffect(() => {
    if (Array.isArray(purchaseOrder.paymentschedules)) {
      const initialPaymentData = purchaseOrder.paymentschedules.map((item, index) => {
        const dueDateString = item.due_date;
        let dueDate;

        if (dueDateString) {
          const parsedDate = new Date(dueDateString);
          if (!isNaN(parsedDate.getTime())) {
            dueDate = parsedDate;
          } else {
            dueDate = '';
          }
        } else {
          dueDate = '';
        }

        return {
          index,
          bill_id: item.bill_id,
          disabled: item.status == 'Paid',
          due_date: dueDate ? dueDate.toISOString().split('T')[0] : '',
          po_in_total: (
            ((item.amount != null ? Number(item.amount) : 0) /
              Number(paymentPoDetails?.total_amount?.toString())) *
            100
          ).toFixed(2),
          amount: Number(item.amount),
          payment_method: item.payment_method,
          status: item.status
        };
      });

      setPaymentSchedule(initialPaymentData);
    } else {
      setPaymentSchedule([]);
    }
  }, [purchaseOrder]);

  const handleChange = (event, index, field) => {
    const { name, value } = event.target;

    const updatedData = [...paymentSchedule];

    if (field === 'amount') {
      if (Number(value) < 1) {
        toast.error('Value cannot be equal or less then 1', {
          position: 'top-center',
          id: 'less_than_one'
        });
        updatedData[index] = {
          ...updatedData[index],
          [field]: ''
        };
        setPaymentSchedule(updatedData);
        return;
      }

      updatedData[index] = {
        ...updatedData[index],
        amount: value
      };

      if (paymentPoDetails?.total_amount) {
        const percentage = ((Number(value) / Number(paymentPoDetails.total_amount)) * 100).toFixed(
          2
        );
        updatedData[index].po_in_total = percentage;
      }
    } else if (field === 'po_in_total') {
      const numericValue = value.replace('%', '').trim();

      updatedData[index] = {
        ...updatedData[index],
        po_in_total: numericValue
      };

      if (paymentPoDetails?.total_amount) {
        const amount = (Number(numericValue) / 100) * Number(paymentPoDetails.total_amount);

        if (amount < 1) {
          toast.error('Resulting amount cannot be less than 1', {
            position: 'top-center',
            id: 'less_than_one'
          });
          return;
        }

        updatedData[index].amount = Number(amount.toFixed(2));
      }
    } else {
      updatedData[index] = {
        ...updatedData[index],
        [field]: value
      };
    }

    setPaymentSchedule(updatedData);
  };

  const dataToInsert = (): PaymentScheduleForm[] => {
    const paymentSchedules: PaymentScheduleForm[] = paymentSchedule.map((d) => ({
      bill_id: d?.bill_id || null,
      amount: Number(d.amount),
      due_date: d.due_date,
      payment_method: d.payment_method,
      po_in_total: d.po_in_total,
      status: !d.status || d.status === 'Needs Allocation' ? 'Not Approved for Payment' : d.status
    }));
    return paymentSchedules;
  };

  const postPaymentSchedule_ = async () => {
    try {
      const data = dataToInsert();
      const purchaseOrderBills: PurchaseOrderBills = {
        paymentSchedules: data
      };

      await postPaymentSchedule(purchaseOrderBills, String(paymentPoDetails?.po_id ?? ''));

      if (deleteBillsList.length) {
        await handleDeleteBills();
      }

      setDeleteBillsList([]);

      const successMessage =
        deleteBillsList.length > 0
          ? 'Bills updated and deleted successfully!'
          : 'Bills updated successfully!';

      toast.success(successMessage, { position: 'top-center' });
    } catch (error) {
      toast.error('Error during bill update or deletion: ' + error, {
        position: 'top-center'
      });
    } finally {
      setWarningModalVisibility(false);
    }
  };

  const submitForm = async () => {
    if (validator.current.allValid()) {
      setLoader(true);

      const paymentScheduleSum = paymentSchedule
        .reduce((acc, item) => acc + (Number(item.amount) ?? 0), 0)
        .toFixed(2);

      if (Number(paymentScheduleSum) > Number(paymentPoDetails?.total_amount)) {
        toast.error(
          `Total of Bill(s) can't be greater than Total PO Amount that is : ${paymentPoDetails?.total_amount}`,
          {
            position: 'top-center'
          }
        );
        setLoader(false);
      } else if (Number(paymentScheduleSum) < Number(paymentPoDetails?.total_amount)) {
        toast.error(
          `Total of Bill(s) can't be lesser than Total PO Amount that is : ${paymentPoDetails?.total_amount}`,
          {
            position: 'top-center'
          }
        );
        setLoader(false);
      } else {
        const data = dataToInsert();
        const hasInvalidDate = data.some((item) => item.due_date === '2099-01-01');

        if (hasInvalidDate) {
          setShowModal(false);
          setWarningModalVisibility(true);
        } else {
          await postPaymentSchedule_();
          if (onComplete) await onComplete();
          onClose();
          setLoader(false);
        }
      }
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  const handleAddPaymentSchedule = () => {
    const newIndex = paymentSchedule?.length;
    const newPaymentObj = { ...paymentScheduleObj, index: newIndex };
    setPaymentSchedule([...paymentSchedule, newPaymentObj]);
  };

  const handleDeletePaymentSchedule = (indexToDelete: number, billId: number) => {
    setPaymentSchedule((prevData) => prevData?.filter((item) => item.index !== indexToDelete));
    if (billId) {
      setDeleteBillsList((prevList) => [...(prevList || []), billId]);
    }
  };

  const handleDeleteBills = () => {
    const formattedBills = {
      billIds: deleteBillsList.map((id) => ({ id }))
    };
    return deletePurchaseOrderBills(formattedBills.billIds);
  };

  validator.current.purgeFields();

  return (
    <>
      <CustomDialog
        title="Update Payment Schedule"
        open={modalShow}
        maxWidth="md"
        closeDialog={() => onClose()}
        loading={loader}
        content={
          <>
            <Box className="payment-modal-table">
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Purchase Order Number</TableCell>
                      <TableCell>Total PO Amount</TableCell>
                      <TableCell>Total Paid</TableCell>
                      <TableCell>Total Remaining</TableCell>
                      <TableCell>Total Scheduled</TableCell>
                      <TableCell>Vendor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      key={'payment-moda-table'}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{paymentPoDetails?.po_number || '00000'}</TableCell>
                      <TableCell component="th" scope="row">
                        {'$' + paymentPoDetails?.total_amount.toFixed(2)}
                      </TableCell>
                      <TableCell>{'$' + (paymentPoDetails?.paid_amount.toFixed(2) || 0)}</TableCell>
                      <TableCell>
                        {'$' + paymentPoDetails?.remaining_amount.toFixed(2) || 0}
                      </TableCell>
                      <TableCell>
                        {'$' + (paymentPoDetails?.scheduled_amount.toFixed(2) || 0)}
                      </TableCell>
                      <TableCell>{paymentPoDetails?.vendor_name || 'No Vendor Name'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Typography component="div" sx={{ marginBottom: '30px !important' }}>
              Create payment schedule by adding the following fields.
            </Typography>
            {paymentSchedule?.map((d, i) => (
              <Box
                key={i}
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  border: `1px solid #dadada`,
                  borderRadius: 1,
                  p: 2,
                  mb: 3
                }}>
                <fieldset style={{ borderColor: theme.palette.divider }}>
                  <StyledLegend>
                    Payment Schedule
                    <div
                      onClick={() =>
                        !d.disabled &&
                        handleDeletePaymentSchedule(Number(d.index), Number(d?.bill_id))
                      }
                      style={{
                        cursor: d.disabled ? 'not-allowed' : 'pointer',
                        display: 'inline-block',
                        margin: '0 0 6px 12px'
                      }}>
                      <img
                        className="img-fluid link-icon"
                        src={DeleteIcon}
                        alt="Delete Icon"
                        style={{
                          pointerEvents: d.disabled ? 'none' : 'auto'
                        }}
                      />
                    </div>
                  </StyledLegend>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <CustomTextfield
                        type="number"
                        min="0"
                        labelText="Amount"
                        name={`paymentSchedule.${i}.amount`}
                        value={d.amount?.toString() ?? ''}
                        onChange={(event) => handleChange(event, i, 'amount')}
                        isRequired
                        maxLength={250}
                        validator={validator}
                        size="medium"
                        disabled={d.disabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomTextfield
                        labelText="PO Total in %"
                        name={`paymentSchedule.${i}.po_in_total`}
                        value={d.po_in_total}
                        onChange={(event) => handleChange(event, i, 'po_in_total')}
                        isRequired={false}
                        disabled={d.disabled}
                        size="medium"
                        endAdornment="%"
                        type="number"
                        min="0"
                        max="100"
                        step="0.01"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomTextfield
                        validator={validator}
                        labelText="Due Date"
                        name={`paymentSchedule.${i}.due_date`}
                        value={d.due_date}
                        isRequired={true}
                        onChange={(event) => handleChange(event, i, 'due_date')}
                        type="date"
                        size="medium"
                        disabled={d.disabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DropDown
                        label="Payment Method"
                        inputName={`paymentSchedule.${i}.payment_method`}
                        optionText="Select payment method"
                        value={d.payment_method || ''}
                        onChange={(event) => handleChange(event, i, 'payment_method')}
                        options={paymentMethods}
                        validator={validator}
                        disabled={d.disabled}
                        required
                      />
                    </Grid>
                  </Grid>
                </fieldset>
              </Box>
            ))}
            <Typography
              component="div"
              sx={{ width: '100%', textAlign: 'center', marginTop: '30px !important' }}>
              <CustomButton
                onClick={handleAddPaymentSchedule}
                type="info"
                disabled={Number(paymentPoDetails?.remaining_amount) <= 0}
                label={
                  <>
                    <img src={AddCirlce} style={{ marginRight: '10px' }} /> Add another payment
                  </>
                }
              />
            </Typography>
          </>
        }
        actions={
          <>
            <CustomButton onClick={() => onClose()} variant="outlined" label="Discard" />
            <CustomButton
              disabled={!paymentSchedule?.length || Number(paymentPoDetails?.remaining_amount) <= 0}
              onClick={() => submitForm()}
              label="Update Payment Schedule"
            />
          </>
        }
      />

      <CustomDialog
        title={
          <Typography component="div" sx={{ color: '#3cbe8e' }}>
            <img src={CheckCirleSuccess} />
            Success!
          </Typography>
        }
        open={successModal}
        maxWidth="xs"
        closeDialog={() => setSuccessModal(false)}
        loading={loader}
        content={<Typography sx={{ mb: 2 }}>Payment schedule created successfully!</Typography>}
        actions={
          <CustomButton
            onClick={() => {
              setSuccessModal(false);
              onClose();
              if (onComplete) onComplete();
            }}
            label="OK"
          />
        }
      />

      <CustomDialog
        title={
          <Typography component="div" sx={{ color: '#335d87' }}>
            <img src={InfoIcon} width="30px" />
            Warning
          </Typography>
        }
        open={isWarningModalVisible}
        maxWidth="xs"
        closeDialog={() => {
          setWarningModalVisibility(false);
          setShowModal(true);
        }}
        loading={loader}
        content={
          <Typography>
            This payment schedule was created by the system. The due date is still set to 1/1/2099.
            Are you sure you want to proceed with changing this status?
          </Typography>
        }
        actions={
          <>
            <CustomButton
              onClick={() => {
                setWarningModalVisibility(false);
                setShowModal(true);
              }}
              variant="outlined"
              label="Discard"
            />
            <CustomButton onClick={() => postPaymentSchedule_()} label="Update Bill" />
          </>
        }
      />
    </>
  );
}
