import { create } from 'zustand';
import { createOrderSlice } from 'store/orderSlice';
import type { CreateOrderSlice } from 'store/orderSlice';
import { createUserSlice } from 'store/useSlice';
import type { CreateUserSlice } from 'store/useSlice';
import { createPurchaseOrderSlice } from 'store/purchaseOrder';
import type { CreatePurchaseOrderSlice } from 'store/purchaseOrder';
import { procurementWorkspaceSlice } from 'store/procurementWorkspace';
import type { ProcurementWorkspaceSlice } from 'store/procurementWorkspace';
import zukeeper from 'zukeeper';
import type { TransportationRequestSlice } from 'store/transportationRequest';
import { transportationRequestSlice } from 'store/transportationRequest';
import type { TransportationTripsSlice } from 'store/transportationTrips';
import { transportationTripsSlice } from 'store/transportationTrips';
import type { CreatePermissionSlice } from 'store/permissionSlice';
import { createPermissionSlice } from 'store/permissionSlice';
import type { AdminWorkspaceSlice } from 'store/adminWorkspace';
import { adminWorkspaceSlice } from 'store/adminWorkspace';
import type { CustomerSlice } from './customerContact';
import { customerSlice } from './customerContact';
import type { CommonSlice } from './common';
import { commonSlice } from './common';
import type { PaymentWorkspaceSlice } from './paymentWorkspace';
import { paymentWorkspaceSlice } from './paymentWorkspace';
import type { TimeTrackingAndAttendenceSlice } from './timeTrackingAndAttendence';
import { timeTrackingAndAttendenceSlice } from './timeTrackingAndAttendence';
import type { StoreType } from 'api/types';

const useOrderStore = create<CreateOrderSlice>(zukeeper(createOrderSlice));
const useUserStore = create<CreateUserSlice>(zukeeper(createUserSlice));
const usePurchaseOrderStore = create<CreatePurchaseOrderSlice>(zukeeper(createPurchaseOrderSlice));
const useProcurementWorkspaceStore = create<ProcurementWorkspaceSlice>(
  zukeeper(procurementWorkspaceSlice)
);
const useTransportationRequestStore = create<TransportationRequestSlice>(
  zukeeper(transportationRequestSlice)
);
const useTransportationTripStore = create<TransportationTripsSlice>(
  zukeeper(transportationTripsSlice)
);
const useCustomerStore = create<CustomerSlice>(zukeeper(customerSlice));
const usePermissionStore = create<CreatePermissionSlice>(zukeeper(createPermissionSlice));
const useAdminWorkspaceStore = create<AdminWorkspaceSlice>(zukeeper(adminWorkspaceSlice));
const useCommonStore = create<CommonSlice>(zukeeper(commonSlice));
const usePaymentWorkspaceStore = create<PaymentWorkspaceSlice>(zukeeper(paymentWorkspaceSlice));
const usetimeTrackingAndAttendenceStore = create<TimeTrackingAndAttendenceSlice>(
  zukeeper(timeTrackingAndAttendenceSlice)
);

declare global {
  interface Window {
    store: StoreType;
  }
}
window.store = {
  useOrderStore,
  useUserStore,
  usePurchaseOrderStore,
  useProcurementWorkspaceStore,
  useTransportationRequestStore,
  useTransportationTripStore,
  usePermissionStore,
  useAdminWorkspaceStore,
  useCustomerStore,
  useCommonStore,
  usePaymentWorkspaceStore,
  usetimeTrackingAndAttendenceStore
};

export {
  useOrderStore,
  useUserStore,
  usePurchaseOrderStore,
  useProcurementWorkspaceStore,
  useTransportationRequestStore,
  useTransportationTripStore,
  usePermissionStore,
  useAdminWorkspaceStore,
  useCustomerStore,
  useCommonStore,
  usePaymentWorkspaceStore,
  usetimeTrackingAndAttendenceStore
};
