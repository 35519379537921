import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { SvgIcon, Typography, useMediaQuery, useTheme } from '@mui/material';
import { updatePaymentSchedule } from 'api/index';
import type {
  ApiInProgressType,
  GetPurchaseOrders,
  PaymentPoDetails,
  PaymentSchedule,
  PaymentSchedulesItems
} from 'api/types';
import AddCirle from 'assets/images/add_circle.svg';
import EditIcon from 'assets/images/edit.svg';
import Can from 'components/Can';
import EditableTableCell from 'components/EditableTableCell';
import Badge from 'components/NewLayout/Badge';
import DataTable from 'components/NewLayout/Table';
import { paymentMethods } from 'pages/ProcurementWorkspace/components/PaymentScheduleModal';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { usePaymentWorkspaceStore } from 'store/index';
import { dateFormatter, getAssociatedJobs, getPrice, roundToTwoDecimalPlaces } from 'utils/index';
import type { DataTableObjectType } from 'utils/types';
import BillUpdateModal from './BillUpdateModal';

type PaginationData = {
  pageNumber: number;
  pageSize: number;
  searchText: string;
};

const PaymentScheduleTable = React.memo(function PaymentScheduleTable({
  loading,
  paymentSchedule,
  fetchAllPayments,
  totalCount,
  setPaginationData,
  onClickPurchaseOrderNumber
}: {
  loading: boolean;
  fetchAllPayments: () => void;
  paymentSchedule: PaymentSchedule[];
  totalCount: number;
  setPaginationData: (pagination: PaginationData) => void;
  onClickPurchaseOrderNumber: (row: GetPurchaseOrders) => void;
}) {
  const [rowVisible, setRowVisible] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState<string>('');
  const [selectedBillId, setSelectedBillId] = useState<number>(0);
  const [billStatus, setBillStatus] = useState<string>('');
  const [isProjectManager, setIsProjectManger] = useState<boolean>(false);
  const [roles, setRoles] = useState<string[]>([]);
  const [, setIsBasicUser] = useState<boolean>(false);
  const [apiInProgress, setApiInProgress] = useState<ApiInProgressType>({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const updatePaymentScheduleItem = usePaymentWorkspaceStore(
    (state) => state.updatePaymentScheduleItem
  );

  const toggleTr = (row: GetPurchaseOrders, index: number) => {
    const RV = index == rowVisible ? -1 : index;
    setRowVisible(RV);
    setSelectedPurchaseOrderId(row.purchase_order_id);
    let totalAllBillAmount = 0;
    row?.paymentschedules?.map((item: PaymentSchedulesItems) => {
      totalAllBillAmount += parseInt(item.amount?.toString() || '');
    });
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    const userRoles = localStorage.getItem('userRoles');
    const userRolesArr: string[] = userRoles?.split(',') || [];
    setRoles(userRolesArr);
    if (userRolesArr.length === 1 && userRolesArr[0] === 'project_manager') {
      setIsProjectManger(true);
    }
    if (userRolesArr.length === 1 && userRolesArr[0] === 'basic_user') {
      setIsBasicUser(true);
    }
  }, []);

  const onClickAddPaymentItems = () => {
    // setShowPaymentScheduleModal(true);
  };

  const onClickUpdatePaymentItem = (billId, status) => {
    setSelectedBillId(billId);
    setBillStatus(status);
  };

  const onEditingComplete = (dataToUpdate: PaymentSchedulesItems, cellId: string) => {
    setApiInProgress({ ...apiInProgress, [cellId]: false });

    const previousState = usePaymentWorkspaceStore.getState().allPaymentsSchedules;

    dataToUpdate.purchase_order_id = selectedPurchaseOrderId;
    updatePaymentScheduleItem(dataToUpdate);

    updatePaymentSchedule(dataToUpdate, dataToUpdate?.bill_id)
      .then((res: { message: string }) => {
        if (res.message !== 'Payment Schedule updated successfully!') {
          throw new Error(res.message);
        }
      })
      .catch((error) => {
        usePaymentWorkspaceStore.setState({ allPaymentsSchedules: previousState });
        toast.error(
          'Failed to update Purchase Order Request Item: ' + (error.message || 'An error occurred'),
          {
            position: 'top-center',
            id: 'failed_PO'
          }
        );
      });
  };

  function getClosestScheduled(param) {
    if (!param || param.length === 0) return '';

    const currentDate = new Date();

    const closest = param.reduce((closestItem, currentItem) => {
      const currentDueDate = new Date(currentItem.due_date);
      const closestDueDate = new Date(closestItem.due_date);

      return Math.abs(currentDueDate.getTime() - currentDate.getTime()) <
        Math.abs(closestDueDate.getTime() - currentDate.getTime())
        ? currentItem
        : closestItem;
    });

    return dateFormatter(closest.due_date, 'date');
  }

  const columns: DataTableObjectType<GetPurchaseOrders>[] = [
    {
      prop: 's3_uri',
      name: '',
      data: (row, index) => {
        const i: number = parseInt(index?.toString()?.split('-')[0] || '-1');
        return (
          <div className="icon-div">
            <SvgIcon
              sx={{ cursor: 'pointer', marginTop: '8px' }}
              component={rowVisible == i ? KeyboardArrowDown : KeyboardArrowRight}
              onClick={() => toggleTr(row, parseInt(i.toString()))}
            />
            {/* <img
              src={EditIcon}
              className={`img-fluid link-icon`}
              style={{ marginLeft: '8px' }}
              alt="edit icon"
              onClick={() => onClickOpenPaymentScheduleModal(row)}
            /> */}
          </div>
        );
      }
    },
    {
      prop: 'purchase_order_number',
      data: (row) => (
        <div className="icon-div">
          <Typography
            sx={{
              textDecoration: 'underline',
              textDecorationThickness: '1px',
              textUnderlineOffset: '3px',
              cursor: 'pointer'
            }}
            onClick={() => onClickPurchaseOrderNumber(row)}>
            {row.purchase_order_number}
          </Typography>
        </div>
      ),
      name: 'Purchase Order Number',
      isSort: true
    },
    {
      prop: 'total_price',
      data: (row) => '$' + roundToTwoDecimalPlaces(row.total_price || 0.0),
      name: 'Total PO Amount',
      isSort: true
    },
    {
      prop: 'total_paid',
      data: (row) => '$' + roundToTwoDecimalPlaces(getPrice(row, 'total')),
      name: 'Total Paid',
      isSort: true
    },
    {
      prop: 'total_remaining',
      data: (row) => '$' + roundToTwoDecimalPlaces(getPrice(row, 'remaining')),
      name: 'Total Remaining',
      isSort: true
    },
    {
      prop: 'total_scheduled',
      data: (row) => '$' + roundToTwoDecimalPlaces(getPrice(row)),
      name: 'Total Scheduled',
      isSort: true
    },
    {
      prop: 'associatedjob',
      data: (row) => getAssociatedJobs(row?.associatedjob || []),
      name: 'Associated Job'
    },
    {
      prop: 'closest_scheduled',
      data: (row) => getClosestScheduled(row.subRows),
      name: 'Closest Scheduled',
      isSort: true
    },
    {
      prop: 'created_by',
      data: 'created_by',
      name: 'Created By',
      isSort: true
    },
    {
      prop: 'vendor_name',
      data: (row) => <Typography>{row?.vendor?.vendor_name || ''}</Typography>,
      name: 'Vendor',
      isSort: true
    },
    {
      prop: 'created_at',
      data: 'created_at',
      name: 'Created At',
      type: 'date',
      dateFormat: 'date',
      isSort: true
    }
  ];

  const subColumns: DataTableObjectType<PaymentSchedulesItems>[] = [
    {
      name: '',
      prop: 'edit_icon',
      data: (row) => {
        const isAdmin = roles.includes('admin');
        const isEditable = row.status !== 'Paid';

        const editIcon = isAdmin && (
          <div
            onClick={() => isEditable && onClickUpdatePaymentItem(row.bill_id, row.status)}
            style={{
              cursor: isEditable ? 'pointer' : 'not-allowed',
              display: 'inline-block',
              margin: '0 0 6px 12px'
            }}
            className="icon-div"
            aria-disabled={!isEditable}
            title={!isEditable ? 'Editing is not allowed for paid items' : ''}>
            <img
              style={{
                pointerEvents: isEditable ? 'auto' : 'none'
              }}
              src={EditIcon}
              className="img-fluid link-icon"
              alt="Edit icon"
            />
          </div>
        );

        const mobileButton = (
          <Typography
            component="div"
            sx={{
              background: '#335d87',
              padding: '10px 13px 12px 13px',
              display: 'inline',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
            onClick={onClickAddPaymentItems}
            role="button"
            aria-label="Add Payment Items"
            title="Add Payment Items">
            <img src={AddCirle} alt="Add icon" />
          </Typography>
        );

        return isMobile ? mobileButton : editIcon;
      }
    },
    {
      prop: 'amount',
      data: 'amount',
      name: 'Amount'
    },
    {
      prop: 'due_date',
      data: (row) => dateFormatter(row.due_date, 'date'),
      name: 'Due Date'
    },
    {
      prop: 'payment_method',
      data: 'payment_method',
      name: 'Payment Method'
    },
    {
      prop: 'status',
      data: (row) => {
        const s = row.status?.toLowerCase();
        const statusType =
          s == 'paid'
            ? 'success'
            : s == 'not approved for payment' || s == 'not approved' || s == 'failed'
            ? 'failed'
            : 'pending';
        return s && <Badge label={row.status} type={statusType} />;
      },
      name: 'Status'
    },
    {
      prop: 'quickbooks_id',
      name: 'QBD ID',
      data: 'quickbooks_id'
    },
    {
      prop: 'last_updated_in_qbd',
      name: 'Last Updated in QBD',
      data: 'last_updated_in_qbd'
    }
  ];

  return (
    <>
      <DataTable<PaymentSchedule, PaymentSchedulesItems>
        columns={columns}
        subColumns={subColumns}
        items={paymentSchedule}
        rowVisible={rowVisible}
        loading={isLoading}
        isSorting={true}
        serverSidePagination
        totalCount={totalCount}
        dataSource={(pageNumber, pageSize, searchText) =>
          setPaginationData({
            pageNumber,
            pageSize,
            searchText
          })
        }
      />

      {/* {showPaymentScheduleModal && (
        <PaymentScheduleModal
          purchaseOrderId={selectedPurchaseOrderId}
          selectedPoTotalPrice={Number(selectedPoTotalPrice)}
          selectedVendorPayment={selectedVendorPayment}
          paymentPoDetails={paymentPoDetails}
          onClose={() => setShowPaymentScheduleModal(false)}
        />
      )} */}

      {selectedBillId != 0 && (
        <BillUpdateModal
          billId={selectedBillId}
          billStatus={billStatus}
          onClose={() => setSelectedBillId(0)}
          onComplete={() => fetchAllPayments()}
          purchaseOrderId={selectedPurchaseOrderId}
        />
      )}
    </>
  );
});

export default PaymentScheduleTable;
