import { ListItemText } from '@mui/material';
import { ListItemNavButton, NavListItemIcon, renderIcon } from './DashboardLayout';
import { Link } from 'react-router-dom';
import Can from 'components/Can';

interface MenuItem {
  module: string;
  name: string;
  link: string;
  icon: string;
}
const SingleNavItem = ({ menu, open }: { menu: MenuItem; open: boolean }) => (
  <Can I="read" module={menu.module} key={menu.name}>
    <Link to={menu.link}>
      <ListItemNavButton key={menu.name}>
        <NavListItemIcon title={!open ? menu.name : undefined}>
          {renderIcon(menu.icon)}
        </NavListItemIcon>
        <ListItemText primary={menu.name} sx={{ display: open ? 'block' : 'none' }} />
      </ListItemNavButton>
    </Link>
  </Can>
);

export default SingleNavItem;
