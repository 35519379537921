import { Box, Grid, Typography } from '@mui/material';
import type {
  ApiInProgressType,
  GetPurchaseOrders,
  PaymentSchedulesItems,
  PoModalModifiers,
  PurchaseOrderItem
} from 'api/types';
import type { DropdownOptionType } from 'components/inputs/Dropdown';
import CustomButton from 'components/NewLayout/Button';
import CustomDialog from 'components/NewLayout/Dialog';
import DropDown from 'components/NewLayout/Dropdown';
import CustomTextfield from 'components/NewLayout/Textfield';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { deliveryAddresses, nameMaxLength } from 'utils/constants';
import { MAX_11_DIGITS_TWO_DECIMALS_REGEX } from 'utils/index';
import EditPoData from './EditPoData';

const modifierDetailsInitial = {
  shipping_cost: '0.00',
  estimated_taxes: '0.00',
  discount: '0.00',
  deposit: '0.00',
  refund: '0.00'
};

const newRowInitialObj: Partial<PurchaseOrderItem> = {
  purchase_order_item_id: '',
  item_name: '',
  quantity: '',
  unit_of_measure: '',
  price: '',
  project_id: 0,
  description: ''
};

export default function EditPoModal({
  isOpen,
  purchaseOrder,
  deletePurchaseOrderItem,
  setPOModalVisibility,
  onClose,
  allProjects,
  updateSelectedPurchaseOrder,
  setShowUpdatePaymentScheduleModal,
  newRows,
  setNewRows
}: {
  isOpen: boolean;
  purchaseOrder: GetPurchaseOrders;
  deletePurchaseOrderItem: (purchaseOrderItemId: string) => void;
  setPOModalVisibility: (val: boolean) => void;
  onClose: () => void;
  onComplete?: () => void;
  allProjects: DropdownOptionType[];
  setShowUpdatePaymentScheduleModal: (val: boolean) => void;
  updateSelectedPurchaseOrder: (purchaseOrder: GetPurchaseOrders) => void;
  newRows: PurchaseOrderItem[];
  setNewRows: React.Dispatch<React.SetStateAction<PurchaseOrderItem[]>>;
}) {
  const [isLoading] = useState<boolean>(false);
  const [specialInstructions, setSpecialInstructions] = useState<string>(
    purchaseOrder.instructions ?? ''
  );
  const [parentApiInProgress, setParentApiInProgress] = useState<ApiInProgressType>({});
  const [poModifiers, setPOModifiers] = useState<PoModalModifiers>(modifierDetailsInitial);
  const [selectedPaymentTerms, setPaymentTerms] = useState<string>(
    purchaseOrder?.payment_terms || 'Net 30'
  );
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(deliveryAddresses[0].id);
  const [customDeliveryAddress, setCustomDeliveryAddress] = useState('');

  useEffect(() => {
    const modifierDetails = {
      shipping_cost: Number(purchaseOrder?.shipping_cost || 0).toFixed(2),
      estimated_taxes: Number(purchaseOrder?.estimated_taxes || 0).toFixed(2),
      discount: Number(purchaseOrder?.discount || 0).toFixed(2),
      deposit: Number(purchaseOrder?.deposit || 0).toFixed(2),
      refund: Number(purchaseOrder?.refund || 0).toFixed(2)
    };
    setPOModifiers(modifierDetails);

    const matchedAddress = deliveryAddresses.find(
      (address) => address.id === purchaseOrder.delivery_address
    );

    if (matchedAddress) {
      setSelectedDeliveryAddress(matchedAddress.id);
    } else if (!purchaseOrder.delivery_address) {
      setSelectedDeliveryAddress(deliveryAddresses[0].id);
    } else {
      setCustomDeliveryAddress(purchaseOrder.delivery_address || '');
      setSelectedDeliveryAddress('Custom Address');
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<{ name: string; value: string }>) => {
    const { name, value } = event.target;

    if (name === 'payment_terms') {
      setPaymentTerms(value);
      return;
    }

    if (MAX_11_DIGITS_TWO_DECIMALS_REGEX.test(value)) {
      const totalChars = value.replace('.', '').length;
      if (totalChars <= 11) {
        setPOModifiers((prev) => ({
          ...prev,
          [name]: value
        }));
      }
    }
  };

  const handleAddNewRow = () => {
    setNewRows((prev) => [
      ...prev,
      {
        ...newRowInitialObj,
        purchase_order_item_id: `temp-${Date.now()}`
      }
    ]);
  };

  const deleteRow = (purchaseOrderItem: PurchaseOrderItem) => {
    const isNewRow = String(purchaseOrderItem.purchase_order_item_id).startsWith('temp');

    if (isNewRow) {
      setNewRows((prev: PurchaseOrderItem[]) =>
        prev.filter((r) => r.purchase_order_item_id !== purchaseOrderItem.purchase_order_item_id)
      );
    } else {
      purchaseOrderItem?.purchase_order_item_id &&
        deletePurchaseOrderItem(purchaseOrderItem.purchase_order_item_id);
    }
  };

  const handleDeliveryAddressChange = ({
    target: { name, value }
  }: ChangeEvent<HTMLInputElement>) => {
    setSelectedDeliveryAddress(value);
    if (value !== 'Custom Address') {
      setCustomDeliveryAddress('');
    }
  };

  const handleCustomAddressChange = ({
    target: { name, value }
  }: ChangeEvent<HTMLInputElement>) => {
    if (value.length <= nameMaxLength) {
      setCustomDeliveryAddress(value);
    }
  };

  const calculatePoTotalAmount = () => {
    let totalPrice = 0;
    purchaseOrder?.purchase_order_items?.map((item: PurchaseOrderItem) => {
      totalPrice += Number(item.price) * Number(item.quantity);
    });
    const { shipping_cost, estimated_taxes, discount, deposit, refund } = poModifiers;
    const poCalcualtedTotal =
      totalPrice +
      parseFloat(shipping_cost || '0') +
      parseFloat(estimated_taxes ?? '0') -
      parseFloat(discount ?? '0') -
      parseFloat(deposit ?? '0') -
      parseFloat(refund ?? '0');

    return poCalcualtedTotal.toFixed(2);
  };

  const onClickConfirmButton = () => {
    const hasMissingFields = newRows.some((row) => {
      const { item_name, price, project_id, unit_of_measure, quantity } = row;
      return !item_name || !price || !project_id || !unit_of_measure || !quantity;
    });

    if (hasMissingFields) {
      toast.error('All necessary fields must be completed in order to add an item.', {
        position: 'top-center',
        id: 'item_submit_fail'
      });
      return;
    }

    if (selectedDeliveryAddress === 'Custom Address' && !customDeliveryAddress.trim()) {
      toast.error('Please enter a custom delivery address', {
        position: 'top-center',
        id: 'missing_address'
      });
      return;
    }

    if (Number(calculatePoTotalAmount()) <= 0) {
      toast.error('Total PO Amount can not be ZERO or less than ZERO', {
        position: 'top-center',
        id: 'zero_price'
      });
      return;
    }

    const paidAmount =
      purchaseOrder?.paymentschedules?.reduce(
        (total: number, item: PaymentSchedulesItems) =>
          item.status === 'Paid' ? total + Number(item.amount) : total,
        0
      ) || 0;

    if (Number(calculatePoTotalAmount()) < paidAmount) {
      toast.error('Total PO Amount can not be less than paid amount', {
        position: 'top-center',
        id: 'amount_less_than_paid_bills'
      });
      return;
    }

    const purchaseOrderToUpdate: GetPurchaseOrders = {
      ...purchaseOrder,
      ...poModifiers,
      delivery_address:
        selectedDeliveryAddress !== 'Custom Address'
          ? selectedDeliveryAddress
          : customDeliveryAddress,
      payment_terms: selectedPaymentTerms,
      instructions: specialInstructions
    };

    updateSelectedPurchaseOrder(purchaseOrderToUpdate);
    setShowUpdatePaymentScheduleModal(true);
    setPOModalVisibility(false);
  };

  const onEditingCompleteforNewRow = (
    dataToUpdate: Partial<PurchaseOrderItem>,
    tempRowId: string,
    cellId: string
  ) => {
    setParentApiInProgress((prev) => ({ ...prev, [cellId]: true }));
    setNewRows((prevRows) =>
      prevRows.map((row) =>
        row.purchase_order_item_id === tempRowId ? { ...row, ...dataToUpdate } : row
      )
    );
    setParentApiInProgress((prev) => ({ ...prev, [cellId]: false }));
  };

  return (
    <>
      <CustomDialog
        title="Edit Purchase Order"
        open={isOpen}
        maxWidth="lg"
        closeDialog={() => onClose()}
        loading={isLoading}
        content={
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            textAlign="center">
            <Grid item xs={12}>
              <Typography component="div" sx={{ fontSize: '18px', mb: 2 }}>
                Edit Purchase Order Details
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <CustomTextfield
                labelText="Estimated Shipping Cost"
                name="shipping_cost"
                placeholder="$0.00"
                onChange={handleChange}
                value={poModifiers.shipping_cost}
                selectText
              />
            </Grid>
            <Grid item xs={5}>
              <CustomTextfield
                labelText="Estimated Taxes"
                name="estimated_taxes"
                placeholder="$0.00"
                onChange={handleChange}
                value={poModifiers.estimated_taxes}
                type="number"
                selectText
              />
            </Grid>
            <Grid item xs={5}>
              <CustomTextfield
                labelText="Discount"
                name="discount"
                placeholder="$0.00"
                onChange={handleChange}
                value={poModifiers.discount}
                type="number"
                selectText
              />
            </Grid>
            <Grid item xs={5}>
              <CustomTextfield
                labelText="Deposit"
                name="deposit"
                placeholder="$0.00"
                onChange={handleChange}
                value={poModifiers.deposit}
                type="number"
                selectText
              />
            </Grid>
            <Grid item xs={5}>
              <CustomTextfield
                labelText="Refund"
                name="refund"
                placeholder="$0.00"
                onChange={handleChange}
                value={poModifiers.refund}
                type="number"
                selectText
              />
            </Grid>

            <Grid container item xs={12} justifyContent="center">
              <Grid container item xs={6} spacing={2}>
                <Grid item xs={12}>
                  <DropDown
                    label="Delivery Address"
                    inputName="delivery_address"
                    onChange={handleDeliveryAddressChange}
                    value={selectedDeliveryAddress}
                    options={deliveryAddresses}
                  />
                </Grid>

                {selectedDeliveryAddress === 'Custom Address' && (
                  <Grid item xs={12}>
                    <CustomTextfield
                      labelText="Custom Address"
                      name="custom_address"
                      placeholder="Enter Custom Address"
                      onChange={handleCustomAddressChange}
                      value={customDeliveryAddress}
                      isTextarea
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography component="div" sx={{ fontSize: '18px', mb: 2, mt: 2 }}>
                Are you sure that you would like to Edit the following purchase order?
              </Typography>
              <EditPoData
                handleChange={handleChange}
                purchaseOrder={purchaseOrder}
                newRows={newRows}
                updateSelectedPurchaseOrder={updateSelectedPurchaseOrder}
                deliveryAddress={
                  selectedDeliveryAddress !== 'Custom Address'
                    ? selectedDeliveryAddress
                    : customDeliveryAddress
                }
                parentApiInProgress={parentApiInProgress}
                setParentApiInProgress={setParentApiInProgress}
                onEditingCompleteforNewRow={(
                  dataToUpdate: Partial<PurchaseOrderItem>,
                  tempRowId: string,
                  cellId: string
                ) => onEditingCompleteforNewRow(dataToUpdate, tempRowId, cellId)}
                specialInstructions={specialInstructions}
                setSpecialInstructions={(res) => setSpecialInstructions(res)}
                poModifierDetails={poModifiers}
                allProjects={allProjects}
                selectedPaymentTerms={selectedPaymentTerms}
                deleteRow={deleteRow}
                newRowButton={() => (
                  <Box textAlign={'left'}>
                    <CustomButton onClick={handleAddNewRow} label="+" />
                  </Box>
                )}
              />
            </Grid>
          </Grid>
        }
        actions={
          <>
            <CustomButton variant="outlined" onClick={() => onClose()} label="Cancel" />
            <CustomButton onClick={() => onClickConfirmButton()} label="Continue" />
          </>
        }
      />
    </>
  );
}
